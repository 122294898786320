import React from "react";
import _ from "lodash";
import { toJS } from "mobx";
import moment from "moment-timezone";
import styled from "styled-components";
import { Table, Th, Tr, Td } from "./elements/Table";
import Check from "./icons/Check";
import Exclamation from "./icons/Exclamation";
import { getMatchupSlug } from "../utils/game";
import { Link } from "react-router-dom";
import rem from "../utils/rem";

const GameIncidentWrapper = styled.div`
  padding: 10px 0;
`;

const StatusIconWrapper = styled.span`
  padding-right: 5px;
`;

const SmallText = styled.span`
  font-size: ${rem(13)};
  color: #333;
`;

const timeZone = process.env.REACT_APP_TIMEZONE;

class IncidentOverviewTable extends React.Component {
  render() {
    const [systemIncidents, gameIncidents] = _.partition(
      this.props.data,
      (incident) => incident.systemWide,
    );
    const systemIncidentsTable = (
      <GameIncidentWrapper key="system">
        <GameIncidents label="System Wide" incidents={systemIncidents} />
      </GameIncidentWrapper>
    );

    const groupedGameIncidents = _.chain(gameIncidents)
      .groupBy((incident) => getMatchupSlug(incident.game))
      .map((incidents, gamePk) => {
        return (
          <GameIncidentWrapper key={gamePk}>
            <GameIncidents label={gamePk} incidents={toJS(incidents)} />
          </GameIncidentWrapper>
        );
      })
      .value();

    const groupedIncidents = groupedGameIncidents;
    if (!_.isEmpty(systemIncidents)) {
      groupedGameIncidents.unshift(systemIncidentsTable);
    }
    const org = process.env.REACT_APP_SASS_ORG;

    return (
      <div>
        <h2>
          <a name="incidents">
            Incident History <SmallText>(all times {timeZone})</SmallText>
          </a>
        </h2>
        {!_.isEmpty(groupedIncidents)
          ? groupedIncidents
          : "No open incidents. Everything seems to be running smoothly 👌"}
      </div>
    );
  }
}

const Time = styled.div`
  color: #a9a9a9;
`;

const DateDisplay = (props) => {
  const { date } = props;
  const momentDate = moment(date).tz(timeZone);
  const formattedDate = momentDate.format("M/D/YYYY");
  const formattedTime = momentDate.format("h:mm A");
  return (
    <div>
      <div>{formattedTime}</div>
      <Time>{formattedDate}</Time>
    </div>
  );
};

const GameIncidents = (props) => {
  const { label, incidents } = props;
  const rows = incidents.map((incident) => {
    const { id, name, createdAt, incidentUpdates } = incident;
    const latestUpdate = _.first(incidentUpdates);
    const status = latestUpdate && latestUpdate.status;

    return (
      <Tr key={id}>
        <Td>
          <div>
            <StatusIconWrapper>
              {status && status.toUpperCase() === "RESOLVED" ? (
                <Check shape="circle" />
              ) : (
                <Exclamation shape="circle" />
              )}
            </StatusIconWrapper>
            <Link to={`/incidents/${id}`}>{name}</Link>
          </div>
        </Td>
        <Td>
          <DateDisplay date={createdAt} />
        </Td>
        <Td>
          <DateDisplay date={latestUpdate.createdAt} />
        </Td>
        <Td>{status}</Td>
        <Td>{latestUpdate.severity}</Td>
        <Td>
          {latestUpdate.eta ? <DateDisplay date={latestUpdate.eta} /> : "-"}
        </Td>
        <Td>
          <div style={{ whiteSpace: "pre-wrap" }}>
            {latestUpdate.description}
          </div>
        </Td>
      </Tr>
    );
  });

  return (
    <React.Fragment>
      <Table width="1330px">
        <colgroup>
          <col width="19%" />
          <col width="8%" />
          <col width="8%" />
          <col width="7%" />
          <col width="7%" />
          <col width="8%" />
        </colgroup>
        <thead>
          <Tr>
            <Th strong>{label}</Th>
            <Th>Opened At</Th>
            <Th>Updated At</Th>
            <Th>Status</Th>
            <Th>Severity</Th>
            <Th>ETA</Th>
            <Th textAlignment="left">Latest Update</Th>
          </Tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </React.Fragment>
  );
};

export default IncidentOverviewTable;
