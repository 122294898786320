import React from "react";
import styled from "styled-components";
import { Td, TdMissingWithTooltip, TdWithTooltip } from "../SetiTable.styled";
import { floatToPercentCeil, floatToPercentFloor } from "../../utils/format";
import rem from "../../utils/rem";
import Check from "../icons/Check";
import Exclamation from "../icons/Exclamation";
import CheckWithExclamation from "../icons/CheckWithExclamation";
import GameStatuses from "../../constants/GameStatuses";
import trackingVersionMapping from "../../constants/TrackingVersionMapping";

const WARN_COLOR = "#fff59d";
const CRITICAL_COLOR = "#ef9a9a";
const NO_DATA_COLOR = "#d3d3d3";
const PPD_COLOR = "#f2f2f9";
const BG_COLOR = "#f0f0f0";

const GameStatusIcon = styled.div`
  font-size: ${rem(16)};
`;

const getColorFromStops = (stops, value) => {
  for (let i = stops.length - 1; i >= 0; i--) {
    const [stop, color] = stops[i];
    if (value >= stop) {
      return color;
    }
  }
};

const getTdWithTooltip = (value, toolTipText, props = {}, ceil) => {
  const { textAlignment = "center", bgColor = "inherit" } = props;

  return (
    <TdWithTooltip
      textAlignment={textAlignment}
      bgcolor={bgColor}
      data-total-pitches-tooltip={toolTipText}
    >
      {ceil ? floatToPercentCeil(value) : floatToPercentFloor(value)}%
    </TdWithTooltip>
  );
};

const RangeTd = (props) => {
  const { stops, count, totalPitches, trackingVersion, ceil } = props;
  if (totalPitches === 0) {
    return <Td bgcolor={NO_DATA_COLOR}>-</Td>;
  }

  if (trackingVersionMapping.onlyPitchToPlate.includes(trackingVersion)) {
    return (
      <TdMissingWithTooltip
        textAlignment="center"
        bgcolor={NO_DATA_COLOR}
        data-total-pitches-tooltip="N/A"
      ></TdMissingWithTooltip>
    );
  }

  const value = count / totalPitches;
  const toolTipText = `${count} of ${totalPitches}`;
  return getTdWithTooltip(
    value,
    toolTipText,
    {
      bgColor: getColorFromStops(stops, value),
    },
    ceil,
  );
};

const RangePlayerTd = (props) => {
  const { stops, count, totalPitches, trackingVersion } = props;

  if (totalPitches === 0) {
    return <Td bgcolor={NO_DATA_COLOR}>-</Td>;
  }
  if (
    trackingVersionMapping.onlyFullPitch.includes(trackingVersion) ||
    trackingVersionMapping.onlyPitchToPlate.includes(trackingVersion)
  ) {
    return (
      <TdMissingWithTooltip
        textAlignment="center"
        bgcolor={NO_DATA_COLOR}
        data-total-pitches-tooltip="N/A"
      ></TdMissingWithTooltip>
    );
  }

  const value = count / totalPitches;
  const toolTipText = `${count} of ${totalPitches}`;
  return getTdWithTooltip(value, toolTipText, {
    bgColor: getColorFromStops(stops, value),
  });
};

const RangeBiomechanicsTd = (props) => {
  const { stops, count, totalPitches, trackingVersion } = props;

  if (totalPitches === 0) {
    return <Td bgcolor={NO_DATA_COLOR}>-</Td>;
  }
  if (!trackingVersionMapping.biomechanicsAndPose.includes(trackingVersion)) {
    return (
      <TdMissingWithTooltip
        textAlignment="center"
        bgcolor={NO_DATA_COLOR}
        data-total-pitches-tooltip="N/A"
      ></TdMissingWithTooltip>
    );
  }

  const value = count / totalPitches;
  const toolTipText = `${count} of ${totalPitches}`;
  return getTdWithTooltip(value, toolTipText, {
    bgColor: getColorFromStops(stops, value),
  });
};

const RangePoseTd = (props) => {
  const { stops, count, totalPitches, trackingVersion } = props;

  if (totalPitches === 0) {
    return <Td bgcolor={NO_DATA_COLOR}>-</Td>;
  }
  if (
    !trackingVersionMapping.pose.includes(trackingVersion) &&
    !trackingVersionMapping.biomechanicsAndPose.includes(trackingVersion)
  ) {
    return (
      <TdMissingWithTooltip
        textAlignment="center"
        bgcolor={NO_DATA_COLOR}
        data-total-pitches-tooltip="N/A"
      ></TdMissingWithTooltip>
    );
  }

  const value = count / totalPitches;
  const toolTipText = `${count} of ${totalPitches}`;
  return getTdWithTooltip(value, toolTipText, {
    bgColor: getColorFromStops(stops, value),
  });
};

const RangeVideoTd = (props) => {
  const { count, totalPitches, angle, availableAngles, sportId, stops } = props;
  const value = count / totalPitches;
  const toolTipText = `${count} of ${totalPitches}`;

  if (totalPitches === 0) {
    return <Td bgcolor={NO_DATA_COLOR}>-</Td>;
  }

  if (angle === "LDBroadcast" && sportId === 1) {
    return getTdWithTooltip(value, toolTipText, {
      bgColor: getColorFromStops(stops, value),
    });
  }

  if (availableAngles && !availableAngles.includes(angle)) {
    return (
      <TdMissingWithTooltip
        textAlignment="center"
        bgcolor={NO_DATA_COLOR}
        data-total-pitches-tooltip="N/A"
      ></TdMissingWithTooltip>
    );
  }

  return getTdWithTooltip(value, toolTipText, {
    bgColor: getColorFromStops(stops, value),
  });
};

const FilteredRangeTd = (props) => {
  const { filtered } = props;
  if (filtered) {
    return null;
  } else {
    return RangeTd(props);
  }
};

const AggregateTd = (props) => {
  const { count, totalPitches, ceil } = props;
  if (totalPitches === 0 || totalPitches === undefined) {
    return <Td bgcolor={NO_DATA_COLOR}>-</Td>;
  }

  const value = count / totalPitches;
  const toolTipText = `${count} of ${totalPitches}`;
  return getTdWithTooltip(
    value,
    toolTipText,
    {
      bgColor: BG_COLOR,
    },
    ceil,
  );
};

const FilteredAggregateTd = (props) => {
  const { filtered } = props;
  if (filtered) {
    return null;
  } else {
    return AggregateTd(props);
  }
};

const getGameStatus = (gamePk, gameStatuses) => {
  if (!gameStatuses[gamePk]) {
    return <Check shape="circle" />;
  }

  let icon;
  switch (gameStatuses[gamePk]) {
    case GameStatuses.OPEN_INCIDENTS_RESOLVED:
      icon = <CheckWithExclamation shape="circle" />;
      break;
    case GameStatuses.OPEN_INCIDENTS:
      icon = <Exclamation shape="circle" />;
      break;
    default:
      break;
  }

  return <a href="#incidents">{icon}</a>;
};

const PointerText = styled.span`
  cursor: pointer;
`;

const TooltipWrapper = styled.div`
  max-width: ${rem(300)};
`;

export {
  PointerText,
  TooltipWrapper,
  RangeTd,
  RangePlayerTd,
  RangeBiomechanicsTd,
  RangePoseTd,
  RangeVideoTd,
  getGameStatus,
  AggregateTd,
  FilteredRangeTd,
  FilteredAggregateTd,
  WARN_COLOR,
  CRITICAL_COLOR,
  NO_DATA_COLOR,
  PPD_COLOR,
  BG_COLOR,
  GameStatusIcon,
  getColorFromStops,
  getTdWithTooltip,
};
