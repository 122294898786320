import clippyClient from "./clippyClient";

class ClippyApi {
  clearCache = gamePk => {
    return clippyClient
      .put(`metadata/videos/${gamePk}/cache?thumbnails=false&category=ALL`)
      .then(response => response.data);
  };
}

export default ClippyApi;
