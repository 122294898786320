import gameStates from "../constants/gameStates";
const ENDED_EARLY_EVENTS = ["Suspended:", "Completed"];

export const getGameState = (status = {}, linescore = {}) => {
  const { detailedState = "" } = status;
  const category = detailedState.split(" ")[0];
  const endedEarly = ENDED_EARLY_EVENTS.includes(category);
  const gameStateLookup = endedEarly ? "Final" : detailedState;

  if (category === "Delayed:") {
    return `${detailedState} / ${linescore.inningHalf} ${linescore.currentInning}`;
  }

  return gameStates[gameStateLookup]
    ? gameStates[gameStateLookup](linescore)
    : detailedState;
};
