import _ from "lodash";
import { action, computed, extendObservable, reaction } from "mobx";
import moment from "moment-timezone";
import FormStore from "./formStore";
import { RouteConstants } from "../constants/RouteConstants";

const POLLING_INTERVAL_MS = 10000;

class TrackingAccuracyReportStore extends FormStore {
  constructor(veracityApi, routerStore, veracityStore) {
    super(routerStore);

    this.veracityApi = veracityApi;
    this.routerStore = routerStore;
    this.veracityStore = veracityStore;

    // Current time in ET
    const now = moment().tz("America/New_York");
    this.defaults = {
      date: (now.hour() < 10 ? now.subtract(1, "day") : now).format(
        "YYYY-MM-DD",
      ),
      selectedPitches: [],
    };

    extendObservable(this, {
      isLoading: computed(() => _.isEmpty(this.data) && this.isFetching),
      isFetching: true,
      updatedAt: "",
      data: undefined,
      selectedPitches: this.defaults["selectedPitches"],
      formValues: {
        date: this.defaults["date"],
      },
      fetchData: action(() => {
        this.isFetching = true;
        this.fetchTrackingAccuracyReport()
          .then((data) => {
            this.data = data;
            this.isFetching = false;
            this.updatedAt = moment().format("LTS");
          })
          .catch((e) => {
            this.isFetching = false;
          });
      }),
      startPolling: () => {
        this.poller = setInterval(() => {
          // Only fetch data if we are looking at today's games.
          if (this.get("date") === moment().format("YYYY-MM-DD")) {
            this.fetchData();
          }
        }, POLLING_INTERVAL_MS);
      },
      stopPolling: () => {
        clearInterval(this.poller);
      },
      setSelectedPitches: action((values) => {
        this.selectedPitches = values;
      }),
      showModal: computed(() => {
        return this.selectedPitches.length > 0;
      }),
      updateDate: action((date) => {
        this.update("date")(date);
        this.cancelTrackingAccuracyReport();
        this.data = undefined;
      }),
      resetStore: action(() => {
        this.selectedPitches = this.defaults["selectedPitches"];
      }),
    });

    reaction(
      () => this.isTrackingTabActive() && this.routerStore.location,
      () => {
        if (this.isTrackingTabActive()) {
          this.updateFromUrlParams(this.routerStore.location.search);
          this.fetchData();
        } else {
          this.resetStore();
        }
      },
      true,
    );
  }
  transitionToCreateIncident = (game) => {
    this.updateNewIncidentMetadata(game);
    this.routerStore.history.push(RouteConstants.INCIDENTS_NEW);
  };

  transitionToCreateSystemWideIncident = () => {
    this.veracityStore.updateNewSystemIncidentMetadata(this.get("date"));
    this.routerStore.history.push(RouteConstants.INCIDENTS_NEW);
  };

  fetchTrackingAccuracyReport() {}
  cancelTrackingAccuracyReport() {}

  isTrackingTabActive() {}

  updateNewIncidentMetadata(game) {}
}

export default TrackingAccuracyReportStore;
