import React from "react";
import PropTypes from "prop-types";
import {
  CheckedWrapper,
  Checkmark,
  ClickTarget,
  Label,
  Unchecked,
  Wrapper
} from "./Checkbox.styled";

class Checkbox extends React.PureComponent {
  constructor(props) {
    super(props);

    this._handleClick = this._handleClick.bind(this);
  }

  _handleClick(e) {
    e.target.value = this.props.value;
    e.target.checked = !this.props.isChecked;
    this.props.handleClick(e);
  }

  render() {
    const { isChecked, label } = this.props;

    return (
      <Wrapper>
        <ClickTarget onClick={this._handleClick}>
          {isChecked ? (
            <CheckedWrapper>
              <Checkmark />
            </CheckedWrapper>
          ) : (
            <Unchecked />
          )}
          <Label checked={isChecked}>{label}</Label>
        </ClickTarget>
      </Wrapper>
    );
  }
}

Checkbox.propTypes = {
  handleClick: PropTypes.func,
  isChecked: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Checkbox;
