export const RouteConstants = {
  BASE: "/",
  LOGIN: "/login",
  INCIDENTS_NEW: "/incidents/new",
  LATEST_INCIDENTS: "/incidents",
  INCIDENTS: "/incidents/:id",
  MILB_TRACKING: "/milb/tracking",
  ATLANTIC_TRACKING: "/atlantic/tracking",
  AFL_TRACKING: "/afl/tracking",
  FSL_TRACKING: "/fsl/tracking",
  NON_MLB_STATCAST: "/nonmlb/tracking",
  AAA_TRACKING: "/aaa/tracking",
  AA_TRACKING: "/aa/tracking",
  HighA_TRACKING: "/high-a/tracking",
  A_TRACKING: "/a/tracking",
  ROOKIE_TRACKING: "/rookie/tracking",
  AMATEUR_TRACKING: "/amateur/tracking",
  WINTER_TRACKING: "/winter/tracking",
  INDEPENDENT_INTERNATIONAL_TRACKING: "/independent-international/tracking",
  SAAS_TRACKING: `/${process.env.REACT_APP_SASS_ORG}/tracking`,
};
