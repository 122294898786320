import React from "react";
import { inject, observer } from "mobx-react";
import gameActionOptions from "../../constants/gameActionOptions";
import GameActionsDropDown from "../../components/GameActionsDropDown";

class SaasGameActionDropDownContainer extends React.Component {
  render() {
    const { SaasGameActionStore } = this.props.rootStore;

    return (
      <GameActionsDropDown
        game={this.props.game}
        gameActionOptions={[
          { label: "Create Incident", value: "create_incident" },
        ]}
        transitionToCreateIncident={
          SaasGameActionStore.trackingAccuracyReportSource
            .transitionToCreateIncident
        }
        clearClippyCache={SaasGameActionStore.clearClippyCache}
        clearStatsApiCache={SaasGameActionStore.clearStatsApiCache}
        resendGame={SaasGameActionStore.resendGame}
      />
    );
  }
}

export default inject("rootStore")(observer(SaasGameActionDropDownContainer));
