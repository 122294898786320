import React from "react";
import moment from "moment";
import { Table, Td, Tr } from "../SetiTable.styled";
import MatchupLink from "../elements/MatchupLink";
import MLBGameActionDropDownContainer from "../../containers/MLBGameActionDropDownContainer";
import {
  GameStatusIcon,
  getGameStatus,
  PPD_COLOR,
  BG_COLOR,
} from "./ReportElements";
import { getGameState } from "../../utils/gameState";
import { FooterRows } from "./mlb/FooterRows";
import { HeaderRows } from "./mlb/HeaderRows";
import { AggregateHealthRow } from "./mlb/AggregateHealthRow";
import { DataColumns } from "./mlb/DataColumns";

class MLBTrackingAccuracyReportTable extends React.Component {
  render() {
    const { data, canCreateIncident, gameStatuses, groupsToShow } = this.props;
    const year = data.date.split("-")[0];
    const isPreHawkeye = year < 2020;
    //Pose tracking was backfilled with the start of the 2020 HE integration

    const sportId = process.env.REACT_APP_DEFAULT_SPORT_ID;

    const bodyRows = data.records.map((record) => {
      const { game, tracking } = record;
      const { status = {}, linescore = {} } = game;

      return (
        <Tr key={game.gamePk}>
          <Td textAlignment="left" noWrap>
            <div className="flex-between">
              <MatchupLink game={game} />
              <GameStatusIcon>
                {getGameStatus(game.gamePk, gameStatuses)}
              </GameStatusIcon>
            </div>
          </Td>
          <Td textAlignment="right">{game.gamePk}</Td>
          {game.status.startTimeTBD ? (
            <Td textAlignment="right" bgcolor={PPD_COLOR}>
              TBD
            </Td>
          ) : (
            <Td textAlignment="right" noWrap>
              {moment(game.gameDate).local().format("LT")}
            </Td>
          )}
          <Td>{getGameState(status, linescore)}</Td>
          {canCreateIncident ? (
            <Td noPadding bgcolor={BG_COLOR}>
              <MLBGameActionDropDownContainer game={game} />
            </Td>
          ) : null}
          <DataColumns
            game={game}
            tracking={tracking}
            isPreHawkeye={isPreHawkeye}
            groupsToShow={groupsToShow}
          />
        </Tr>
      );
    });

    return (
      <Table>
        <colgroup>
          <col width="8%" />
          <col />
          <col />
          <col width="7%" />
          {canCreateIncident && <col width="8%" />}
        </colgroup>
        <thead>
          <HeaderRows
            isPreHawkeye={isPreHawkeye}
            canCreateIncident={canCreateIncident}
            groupsToShow={groupsToShow}
          />
        </thead>
        <tbody>
          {bodyRows}
          <AggregateHealthRow
            data={data}
            isPreHawkeye={isPreHawkeye}
            canCreateIncident={canCreateIncident}
            groupsToShow={groupsToShow}
            sportId={sportId}
          />
        </tbody>
        <tfoot>
          <FooterRows
            isPreHawkeye={isPreHawkeye}
            year={year}
            canCreateIncident={canCreateIncident}
            groupsToShow={groupsToShow}
          />
        </tfoot>
      </Table>
    );
  }
}

MLBTrackingAccuracyReportTable.defaultProps = {
  data: { records: [] },
};

export default MLBTrackingAccuracyReportTable;
