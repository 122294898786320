import React from "react";
import styled from "styled-components";
import rem from "../../utils/rem";
import Check from "./Check";
import Exclamation from "./Exclamation";

const Wrapper = styled.div`
  position: relative;
`;

const RelativeExclamation = styled(Exclamation)`
  position: absolute;
  top: ${rem(-2)};
  left: ${rem(14)};
  font-size: 50%;
`;

export default class CheckWithExclamation extends React.PureComponent {
  render() {
    return (
      <Wrapper>
        <Check shape="circle" />
        <RelativeExclamation shape="circle" />
      </Wrapper>
    );
  }
}
