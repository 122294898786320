import React from "react";
import styled, { keyframes } from "styled-components";

const LoadingWrapper = styled.div`
  align-self: center;
  display: flex;
  height: 100px;
  justify-content: space-between;
  margin: 0 auto;
  width: 45px;
`;

const stretchDelay = keyframes`
  0%, 40%, 100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1.0);
  }
`;

const LoadingBar = styled.div`
  background-color: #333;
  height: 100%;
  width: 6px;
  animation: ${stretchDelay} 1.2s infinite ease-in-out;
  animation-delay: ${props => props.delay || "0s"};
`;

// Loading bar concept stolen from http://tobiasahlin.com/spinkit/
const Loading = () => (
  <LoadingWrapper>
    <LoadingBar />
    <LoadingBar delay={"-1.1s"} />
    <LoadingBar delay={"-1.0s"} />
    <LoadingBar delay={"-0.9s"} />
    <LoadingBar delay={"-0.8s"} />
  </LoadingWrapper>
);

export default Loading;
