import React from "react";
import ReactDOM from "react-dom";
import "url-search-params-polyfill";
import "typeface-roboto";
import "font-awesome/css/font-awesome.min.css";
import "rc-tooltip/assets/bootstrap.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import App from "./App";
import { unregister } from "./registerServiceWorker";

ReactDOM.render(<App />, document.getElementById("root"));
unregister();
