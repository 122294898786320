import { toast } from "react-toastify";

class MILBGameActionStore {
  constructor(jaredApi, milbTrackingAccuracyReportSource) {
    this.jaredApi = jaredApi;
    this.milbTrackingAccuracyReportSource = milbTrackingAccuracyReportSource;
  }

  resendGame = gamePk => {
    return this.jaredApi
      .resendMILBGame(gamePk)
      .then(() => {
        toast("Game resend has started.");
        this.milbTrackingAccuracyReportSource.fetchData();
      })
      .catch(err =>
        toast.error("Error: Game has not been resent due to " + err, {
          autoClose: false
        })
      );
  };
}

export default MILBGameActionStore;
