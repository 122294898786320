import IncidentTypes from "../constants/IncidentTypes";
import { RouteConstants } from "../constants/RouteConstants";
import TrackingAccuracyReportStore from "./trackingAccuracyReportStore";

class HighATrackingAccuracyReportStore extends TrackingAccuracyReportStore {
  fetchTrackingAccuracyReport() {
    return this.veracityApi.fetchHighATrackingAccuracyReport(this.get("date"));
  }

  cancelTrackingAccuracyReport() {
    return this.veracityApi.cancelHighATrackingAccuracyReport();
  }

  isTrackingTabActive() {
    return this.routerStore.isHighATrackingAccuracyReportTab;
  }

  updateNewIncidentMetadata(game) {
    return this.veracityStore.updateNewIncidentMetadata(
      IncidentTypes.HighA,
      game,
      this.get("date"),
      RouteConstants.HighA_TRACKING,
    );
  }
}

export default HighATrackingAccuracyReportStore;
