import IncidentTypes from "../constants/IncidentTypes";

const absIncidentTypeIds = [
  IncidentTypes.ATLANTIC.id,
  IncidentTypes.AFL.id,
  IncidentTypes.FSL.id
];

export const isAbsIncident = incidentType =>
  absIncidentTypeIds.includes(incidentType.id);
