import { action, reaction, extendObservable, toJS } from "mobx";
import moment from "moment-timezone";
import FormStore from "./formStore";
import IncidentTypes from "../constants/IncidentTypes";

//Problem is that all of the filtering in veracity come from the DB. There is no Stats API filter in the return
// I am only adding new classes to the return
// Without owner added to the DB this filtering would need to be done somewhere else
// You can explore that filtering downstream here in the JS

class LatestIncidentsStore extends FormStore {
  constructor(veracityApi, routerStore) {
    super(routerStore);

    this.veracityApi = veracityApi;
    this.routerStore = routerStore;

    this.defaults = {
      incidentTypes: "",
      updateDate: moment()
        .set("month", 1) // 0-indexed so 1 is february
        .set("date", 15)
        .format("YYYY-MM-DD"),
      status: null,
      severity: null,
      page: 0,
      limit: 20,
      owner: null,
    };

    extendObservable(this, {
      isFetching: false,
      data: { data: [], total: 0 },
      formValues: {
        incidentTypes: this.defaults["incidentTypes"],
        updateDate: this.defaults["updateDate"],
        status: this.defaults["status"],
        severity: this.defaults["severity"],
        owner: this.defaults["owner"],
        page: this.defaults["page"],
        limit: this.defaults["limit"],
      },
      fetchData: action(() => {
        this.isFetching = true;

        // When this gets push into the URL it is a comma separate list so we need to parse it to an array
        const parsedTypes = toJS(this.get("incidentTypes"))
          .split(",")
          .filter((type) => type.length > 0);
        const incidentTypes =
          parsedTypes.length > 0
            ? parsedTypes
            : Object.values(IncidentTypes).map((type) => type.id);

        const date = moment(this.get("updateDate")).toISOString();

        veracityApi
          .fetchRecentIncidents(
            incidentTypes,
            date,
            this.get("status"),
            this.get("severity"),
            this.get("owner"),
            this.get("page"),
            this.get("limit"),
          )
          .then((data) => {
            this.data = data;
            this.isFetching = false;
          })
          .catch((e) => {
            this.isFetching = false;
          });
      }),
      updateIncidentTypes: action((newType) => {
        // When this gets push into the URL it is a comma separate list so we need to parse it to an array
        let newTypes = toJS(this.get("incidentTypes"))
          .split(",")
          .filter((type) => type.length > 0);
        if (newTypes.includes(newType)) {
          newTypes = newTypes.filter((t) => t !== newType);
        } else {
          newTypes.push(newType);
        }
        this.update("incidentTypes")(
          newTypes.length > 0 ? newTypes : this.defaults["incidentTypes"],
        );

        this.updatePage(this.defaults["page"]);
        this.pushUrlParams();
      }),
      updateDate: action((date) => {
        this.update("updateDate")(date);
        this.updatePage(this.defaults["page"]);
        this.pushUrlParams();
      }),
      updateStatus: action((status) => {
        this.update("status")(status === this.get("status") ? null : status);
        this.updatePage(this.defaults["page"]);
        this.pushUrlParams();
      }),
      updateOwner: action((owner) => {
        this.update("owner")(owner === this.get("owner") ? null : owner);
        this.updatePage(this.defaults["page"]);
        this.pushUrlParams();
      }),
      updateSeverity: action((severity) => {
        this.update("severity")(
          severity === this.get("severity") ? null : severity,
        );
        this.updatePage(this.defaults["page"]);
        this.pushUrlParams();
      }),
      updatePage: action((page) => {
        this.update("page")(page);
      }),
      updateLimit: action((limit) => {
        this.update("limit")(limit);
      }),
    });

    reaction(
      () => this.routerStore.isLatestIncidentsTab && this.routerStore.location,
      () => {
        if (this.routerStore.isLatestIncidentsTab) {
          this.updateFromUrlParams(this.routerStore.location.search);
          this.fetchData();
        }
      },
      true,
    );
  }
}

export default LatestIncidentsStore;
