import React from "react";
import styled from "styled-components";
import { Td } from "../SetiTable.styled";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import IncidentForm from "../IncidentForm";
import IncidentTypes from "../../constants/IncidentTypes";
import Button from "../elements/Button";

const FlexFormContainer = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
`;

const TextArea = styled.textarea`
  resize: none;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  border: none;
`;

const DisplayNote = styled.div`
  display: flex;
  flex-direction: column;
  height: 44px;
`;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    width: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};

const ENTER_KEY_CODE = 13;

class GameNote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gamePk: props.gamePk,
      incidentName: undefined,
      isModalOpen: false
    };
    this.createGameIncident = this.createGameIncident.bind(this);
    this.closeIncidentForm = this.closeIncidentForm.bind(this);
  }

  componentWillReceiveProps(props) {
    const newState = {
      gamePk: props.gamePk,
      incidentName: props.incidentName,
      createdAt: props.createdAt
    };
    this.setState(newState);
  }

  createGameIncident(incident) {
    const {
      name,
      status,
      description,
      severity,
      eta,
      incidentType,
      homePlateUmpire
    } = incident;
    if (this.state.gamePk !== undefined && name !== undefined) {
      this.props.createIncident(
        this.state.gamePk,
        name,
        status,
        description,
        severity,
        eta,
        incidentType,
        homePlateUmpire
      );
      this.setState({ isModalOpen: false });
      return Promise.resolve();
    }

    return Promise.reject("Name is required");
  }

  afterSubmit() {
    console.log("Submitted incident!");
  }

  onEnterPress = e => {
    if (e.keyCode === ENTER_KEY_CODE && e.shiftKey === false) {
      e.preventDefault();
      this.setState({ isModalOpen: true });
    }
  };

  closeIncidentForm() {
    const state = this.state;
    state.isModalOpen = false;
    this.setState(state);
  }

  render() {
    const { hasIncidentEdit } = this.props;
    if (!this.props.createdAt) {
      return hasIncidentEdit ? (
        <Td>
          <FlexFormContainer>
            <TextArea
              value={this.state.incidentName}
              onChange={e => this.setState({ incidentName: e.target.value })}
              placeholder="Report an incident..."
              onKeyDown={this.onEnterPress}
            />
            <Modal
              isOpen={this.state.isModalOpen}
              style={customStyles}
              ariaHideApp={false}
            >
              <IncidentForm
                onSubmit={this.createGameIncident}
                afterSubmit={this.afterSubmit}
                gamePk={this.state.gamePk}
                isSystemWide={false}
                incidentType={IncidentTypes.MILB}
                previousIncident={{
                  name: this.state.incidentName,
                  incidentUpdates: [{ status: "Open" }]
                }}
              />
              <Button onClick={this.closeIncidentForm}>Cancel</Button>
            </Modal>
          </FlexFormContainer>
        </Td>
      ) : (
        <Td textAlignment="left">
          <DisplayNote />
        </Td>
      );
    } else {
      return (
        <Td textAlignment="left">
          <DisplayNote>
            <React.Fragment>
              <Link to={`/incidents/${this.props.id}`}>
                {this.props.incidentUpdates.length > 0
                  ? this.props.incidentUpdates[0].description || this.props.name
                  : this.props.name}
              </Link>
            </React.Fragment>
          </DisplayNote>
        </Td>
      );
    }
  }
}

export default GameNote;
