import React from "react";
import { inject, observer } from "mobx-react";
import gameActionOptions from "../constants/gameActionOptions";
import GameActionsDropDown from "../components/GameActionsDropDown";

class AAGameActionDropDownContainer extends React.Component {
  render() {
    const { AAGameActionStore } = this.props.rootStore;

    return (
      <GameActionsDropDown
        game={this.props.game}
        gameActionOptions={gameActionOptions}
        transitionToCreateIncident={
          AAGameActionStore.trackingAccuracyReportSource
            .transitionToCreateIncident
        }
        clearClippyCache={AAGameActionStore.clearClippyCache}
        clearStatsApiCache={AAGameActionStore.clearStatsApiCache}
        resendGame={AAGameActionStore.resendGame}
      />
    );
  }
}

export default inject("rootStore")(observer(AAGameActionDropDownContainer));
