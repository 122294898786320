import jaredClient from "./jaredClient";

class JaredApi {
  resendMLBGame = (gamePk, feedType, mode) => {
    return jaredClient
      .post(
        `admin/games/${gamePk}/${feedType}/recut?overwrite=1&trigger_coaching_video_workflow=1&mode=${mode}`
      )
      .then(response => response.data);
  };

  resendMILBGame = gamePk => {
    return jaredClient
      .post(`games/milb/${gamePk}/recut`)
      .then(response => response.data);
  };

  clearApiCache = gamePk => {
    return jaredClient
      .put(`admin/games/${gamePk}/stats-api/cache`)
      .then(response => response.data);
  };
}

export default JaredApi;
