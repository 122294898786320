const trackingVersionMapping = {
  pose: [103, 104],
  biomechanicsAndPose: [112, 114],
  onlyPitchToPlate: [113], // No spin data but has player tracking
  onlyFullPitch: [111, 109, 100, 101, 106], // No player tracking
  mlbSystemVersion: [112, 103],
  mlbSystemOwner: [1],
  clubOwnedSystemVersion: [114],
  clubOwnedSystemOwner: [2],
};

export default trackingVersionMapping;
