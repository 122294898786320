export default {
  "In Progress": linescore => {
    return `In Progress / ${linescore.inningHalf} ${linescore.currentInning}`;
  },
  Scheduled: () => "Pre-Game",
  Warmup: () => "Pre-Game",
  Postponed: () => "PPD",
  Final: linescore => {
    if (linescore.currentInning !== 9) {
      return `Final/${linescore.currentInning}`;
    }
    return "Final";
  }
};
