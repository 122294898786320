import ReactSelect from "react-select";
import styled from "styled-components";
import rem from "../utils/rem";
import "react-select/dist/react-select.css";

const Select = styled(ReactSelect)`
  font-size: ${rem(12)};
`;

export default Select;
