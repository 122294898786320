import { SportsTennis } from "@material-ui/icons";
import { toast } from "react-toastify";
const sportIdsInMLBDiamond = [1, 22, 586];

class GameActionStore {
  constructor(clippyApi, jaredApi, veracityApi, trackingAccuracyReportSource) {
    this.clippyApi = clippyApi;
    this.jaredApi = jaredApi;
    this.veracityApi = veracityApi;
    this.trackingAccuracyReportSource = trackingAccuracyReportSource;
  }

  clearClippyCache = (gamePk) => {
    return this.clippyApi
      .clearCache(gamePk)
      .then(() => {
        toast(`Clippy cache has been cleared.`);
        this.trackingAccuracyReportSource.fetchData();
      })
      .catch((err) =>
        toast.error(`Error: Clippy cache has not been cleared due to ${err}`, {
          autoClose: false,
        }),
      );
  };

  clearStatsApiCache = (gamePk) => {
    return this.jaredApi
      .clearApiCache(gamePk)
      .then(() => {
        toast(`StatsApi cache has been cleared.`);
        this.trackingAccuracyReportSource.fetchData();
      })
      .catch((err) =>
        toast.error(
          `Error: StatsApi cache has not been cleared due to ${err}`,
          {
            autoClose: false,
          },
        ),
      );
  };

  resendGame = (gamePk, feedType, isMlbVenue, sportId) => {
    let mode = "";
    if (isMlbVenue || sportIdsInMLBDiamond.includes(sportId)) {
      mode = "MLB";
    } else {
      mode = "MiLB";
    }

    return this.jaredApi
      .resendMLBGame(gamePk, feedType, mode)
      .then(() => {
        toast(`Game resend has started.`);
        this.trackingAccuracyReportSource.fetchData();
      })
      .catch((err) =>
        toast.error(`Error: Game has not been resent due to ${err}`, {
          autoClose: false,
        }),
      );
  };
}

export default GameActionStore;
