import React from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import rem from "../utils/rem";
import Loading from "../components/Loading";
import IncidentOverviewTable from "../components/IncidentOverviewTable";
import { TableControls } from "../components/TableControls";
import { inject, observer } from "mobx-react";
import Button from "../components/elements/Button";

const Wrapper = styled.div`
  min-width: 1200px;
  margin: ${rem(30)};
`;

const ContentWrapper = styled.div`
  margin: ${rem(10)} 0;
`;

const DateInput = styled.input`
  text-align: center;
  line-height: ${rem(16)};
  border-radius: ${rem(4)};
  border: 1px solid #aeaeae;
  font-size: ${rem(13)};
  padding: ${rem(6)} ${rem(10)} ${rem(6)};
  cursor: pointer;
`;

const Description = styled.div`
  padding-bottom: ${rem(20)};
`;

const Footer = styled.div`
  font-size: ${rem(10)};
  padding: ${rem(10)} 0;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FlexItem = styled.div`
  align-self: flex-end;
`;

class BaseMLBTrackingAccuracyReportContainer extends React.Component {
  constructor(props) {
    super(props);
    this._handleDateChange = this._handleDateChange.bind(this);
  }

  _handleDateChange(date) {
    const { veracityStore } = this.props.rootStore;
    const formattedDate = date ? date.format("YYYY-MM-DD") : "";
    this.props.reportSource.updateDate(formattedDate);
    veracityStore.fetchIncidents(
      this.props.incidentTypesToFetch,
      formattedDate,
    );
  }

  componentDidMount() {
    const { veracityStore } = this.props.rootStore;
    this.props.reportSource.startPolling();
    const date = this.props.reportSource.get("date");
    veracityStore.fetchIncidents(this.props.incidentTypesToFetch, date);
  }

  componentWillUnmount() {
    this.props.reportSource.stopPolling();
  }

  render() {
    const { veracityStore, tableControlsStore } = this.props.rootStore;
    const {
      header,
      description,
      showSystemIncidentButton,
      reportSource,
      reportTable,
    } = this.props;
    const selectedDate = new moment(reportSource.get("date"));

    return (
      <Wrapper>
        <FlexContainer>
          <FlexItem>
            <h1>{header}</h1>
          </FlexItem>
          {showSystemIncidentButton ? (
            <FlexItem>
              <Button
                onClick={reportSource.transitionToCreateSystemWideIncident}
              >
                Create System Incident
              </Button>
            </FlexItem>
          ) : null}
        </FlexContainer>
        <Description dangerouslySetInnerHTML={{ __html: description }} />
        <FlexContainer>
          <DatePicker
            selected={selectedDate}
            onChange={(date) => {
              this._handleDateChange(date);
              reportSource.pushUrlParams();
            }}
            dateFormat="LL"
            customInput={<DateInput />}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
          <FlexItem>
            <TableControls
              groupsToShow={tableControlsStore.state}
              updateGroupsToShow={tableControlsStore.updateState}
            />
          </FlexItem>
        </FlexContainer>
        <div>
          {reportSource.isLoading ? (
            <Loading />
          ) : (
            <ContentWrapper>
              {reportTable}
              <Footer>Data retrieved at {reportSource.updatedAt}</Footer>
            </ContentWrapper>
          )}
        </div>
        <IncidentOverviewTable data={veracityStore.incidentsOverview} />
      </Wrapper>
    );
  }
}

export default inject("rootStore")(
  observer(BaseMLBTrackingAccuracyReportContainer),
);
