import { Td } from "../../SetiTable.styled";
import coachingVideoMapping from "../../../constants/CoachingVideoMapping";

import {
  CRITICAL_COLOR,
  FilteredRangeTd,
  PPD_COLOR,
  RangeTd,
  RangePlayerTd,
  RangeBiomechanicsTd,
  RangePoseTd,
  RangeVideoTd,
  WARN_COLOR,
} from "../ReportElements";

const columnsPerGroup = {
  showPitches: 3,
  showVideo: 10,
  showHitting: 2,
  showPlayers: 4,
};

export function DataColumns({ game, tracking, isPreHawkeye, groupsToShow }) {
  const {
    status = {},
    coachingVideo = [],
    teams = {},
    trackingVersion = {},
  } = game;

  const sportId = teams.home.team.sport.id;
  let trackingVersionId = trackingVersion.id;

  if (status.codedGameState === "D") {
    let colSpan = 0;
    Object.entries(groupsToShow).map(([key, show]) => {
      colSpan += show ? columnsPerGroup[key] : 0;
    });
    return (
      <Td bgcolor={PPD_COLOR} colSpan={colSpan}>
        PPD
      </Td>
    );
  }

  const {
    pitches,
    pitchesOpticallyTracked,
    broadcastVideo,
    broadcastLDVideo,
    alternateBroadcastVideo,
    chHighSideviewVideo,
    highHomeVideo,
    centerfieldVideo,
    pitchcastVideo,
    firstBasePitcherSideViewVideo,
    thirdBasePitcherSideViewVideo,
    firstBaseBatterSideViewVideo,
    thirdBaseBatterSideViewVideo,
    pitchesWithSpin,
    reducedSpinConfidence,
    bip,
    totalPlays,
    totalHits,
    swingsTracked = 0,
    totalSwings = 0,
    batterBiomechanicsAvailable = 0,
    pitcherBiomechanicsAvailable = 0,
    playerTrackingCompleteness = 0,
    poseTrackingCompleteness = 0,
  } = tracking;

  // Before 2020 the CH Sideview video is used.  After 2020 the HighHome video is used
  const playsWithAdditionalVideo = isPreHawkeye
    ? chHighSideviewVideo
    : highHomeVideo;

  const bipWarnThreshold = isPreHawkeye ? 0.8 : 0.95;
  const bipAlertThreshold = isPreHawkeye ? 0.9 : 0.99999;

  return (
    <>
      {groupsToShow.showPitches && (
        <>
          <RangeTd
            count={pitches}
            totalPitches={totalPlays}
            stops={[[0, CRITICAL_COLOR], [0.99, WARN_COLOR], [0.995]]}
          />
          <FilteredRangeTd
            filtered={!isPreHawkeye} //Column is only shown pre HawkEye
            count={pitchesOpticallyTracked}
            totalPitches={pitches}
            stops={[[0, CRITICAL_COLOR], [0.97, WARN_COLOR], [0.98]]}
          />
          <RangeTd
            count={pitchesWithSpin}
            totalPitches={pitches}
            trackingVersion={trackingVersionId}
            stops={[[0, CRITICAL_COLOR], [0.9, WARN_COLOR], [0.95]]}
          />
          <RangeTd
            count={reducedSpinConfidence}
            totalPitches={pitches}
            trackingVersion={trackingVersionId}
            stops={[[0], [0.001, WARN_COLOR], [0.01, CRITICAL_COLOR]]}
            ceil={true}
          />
        </>
      )}
      {groupsToShow.showVideo && (
        <>
          <RangeVideoTd
            count={broadcastVideo}
            totalPitches={totalPlays}
            angle={coachingVideoMapping.broadcast}
            availableAngles={coachingVideo}
            stops={[[0, CRITICAL_COLOR], [0.995, WARN_COLOR], [0.99999]]}
          />
          <RangeVideoTd
            count={broadcastLDVideo}
            totalPitches={totalPlays}
            angle={coachingVideoMapping.lowDefBroadcast}
            availableAngles={coachingVideo}
            sportId={sportId}
            stops={[[0, CRITICAL_COLOR], [0.995, WARN_COLOR], [0.99999]]}
          />
          <RangeVideoTd
            count={alternateBroadcastVideo}
            totalPitches={totalPlays}
            angle={coachingVideoMapping.alternateBroadcast}
            availableAngles={coachingVideo}
            stops={[[0, CRITICAL_COLOR], [0.995, WARN_COLOR], [0.99999]]}
          />
          <RangeVideoTd
            count={pitchcastVideo}
            totalPitches={totalPlays}
            angle={coachingVideoMapping.pitchcast}
            availableAngles={coachingVideo}
            stops={[[0, CRITICAL_COLOR], [0.995, WARN_COLOR], [0.99999]]}
          />
          <RangeVideoTd
            count={centerfieldVideo}
            totalPitches={totalPlays}
            angle={coachingVideoMapping.centerfield}
            availableAngles={coachingVideo}
            stops={[[0, CRITICAL_COLOR], [0.995, WARN_COLOR], [0.99999]]}
          />
          <RangeVideoTd
            count={isPreHawkeye ? chHighSideviewVideo : highHomeVideo}
            totalPitches={totalPlays}
            angle={
              isPreHawkeye
                ? coachingVideoMapping.chHighSideview
                : coachingVideoMapping.highHome
            }
            availableAngles={coachingVideo}
            stops={[[0, CRITICAL_COLOR], [0.995, WARN_COLOR], [0.99999]]}
          />
          <RangeVideoTd
            count={firstBaseBatterSideViewVideo}
            totalPitches={totalPlays}
            angle={coachingVideoMapping.firstBaseBatterSideview}
            availableAngles={coachingVideo}
            stops={[[0, CRITICAL_COLOR], [0.995, WARN_COLOR], [0.99999]]}
          />
          <RangeVideoTd
            count={thirdBaseBatterSideViewVideo}
            totalPitches={totalPlays}
            angle={coachingVideoMapping.thirdBaseBatterSideview}
            availableAngles={coachingVideo}
            stops={[[0, CRITICAL_COLOR], [0.995, WARN_COLOR], [0.99999]]}
          />
          <RangeVideoTd
            count={firstBasePitcherSideViewVideo}
            totalPitches={totalPlays}
            angle={coachingVideoMapping.firstBasePitcherSideview}
            availableAngles={coachingVideo}
            stops={[[0, CRITICAL_COLOR], [0.995, WARN_COLOR], [0.99999]]}
          />
          <RangeVideoTd
            count={thirdBasePitcherSideViewVideo}
            totalPitches={totalPlays}
            angle={coachingVideoMapping.thirdBasePitcherSideview}
            availableAngles={coachingVideo}
            stops={[[0, CRITICAL_COLOR], [0.995, WARN_COLOR], [0.99999]]}
          />
        </>
      )}
      {groupsToShow.showHitting && (
        <>
          <RangeTd
            count={bip}
            totalPitches={totalHits}
            stops={[
              [0, CRITICAL_COLOR],
              [bipWarnThreshold, WARN_COLOR],
              [bipAlertThreshold],
            ]}
          />
          <RangeBiomechanicsTd
            count={swingsTracked}
            totalPitches={totalSwings}
            trackingVersion={trackingVersionId}
            stops={[[0, CRITICAL_COLOR], [0.9, WARN_COLOR], [0.95]]}
          />
        </>
      )}
      {groupsToShow.showPlayers && (
        <>
          <RangeBiomechanicsTd
            count={batterBiomechanicsAvailable}
            totalPitches={totalPlays}
            trackingVersion={trackingVersionId}
            stops={[[0, CRITICAL_COLOR], [0.9, WARN_COLOR], [0.95]]}
          />
          <RangeBiomechanicsTd
            count={pitcherBiomechanicsAvailable}
            totalPitches={totalPlays}
            trackingVersion={trackingVersionId}
            stops={[[0, CRITICAL_COLOR], [0.9, WARN_COLOR], [0.95]]}
          />
          <RangePlayerTd
            count={playerTrackingCompleteness}
            totalPitches={totalPlays}
            trackingVersion={trackingVersionId}
            stops={[[0, CRITICAL_COLOR], [0.9, WARN_COLOR], [0.95]]}
          />
          <RangePoseTd
            count={poseTrackingCompleteness}
            totalPitches={totalPlays}
            trackingVersion={trackingVersionId}
            stops={[[0, CRITICAL_COLOR], [0.9, WARN_COLOR], [0.95]]}
          />
        </>
      )}
    </>
  );
}
