const gameActionOptions = [
  { label: "Create Incident", value: "create_incident" },
  { label: "Clear API Cache", value: "clear_api_cache" },
  { label: "Clear Clippy Cache", value: "clear_clippy_cache" },
  { label: "Resend Entire Game (Home)", value: "resend_entire_game_home" },
  { label: "Resend Entire Game (Away)", value: "resend_entire_game_away" },
  {
    label: "Resend Entire Game (Network)",
    value: "resend_entire_game_network",
  },
  { label: "Resend Entire Game (CF)", value: "resend_entire_game_cf" },
  { label: "Resend Entire Game (HH)", value: "resend_entire_game_hh" },
  { label: "Resend Entire Game (1B-B)", value: "resend_entire_game_1b_b" },
  { label: "Resend Entire Game (1B-P)", value: "resend_entire_game_1b_p" },
  { label: "Resend Entire Game (3B-B)", value: "resend_entire_game_3b_b" },
  { label: "Resend Entire Game (3B-P)", value: "resend_entire_game_3b_p" },
  {
    label: "Resend Entire Game (Pitchcast/CAM4)",
    value: "resend_entire_game_pitchcast",
  },
];

export default gameActionOptions;
