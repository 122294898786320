import React from "react";
import { getMatchupSlug } from "../../utils/game";

const MatchUpLink = ({ game, useFullNames = false }) => {
  const { gamePk, teams } = game;
  const matchupSlug = getMatchupSlug(game, useFullNames);
  const baseUrl = process.env.REACT_APP_SAAS_RESEARCH_TOOL_BASE_URL;

  return (
    <>
      <a href={`${baseUrl}/games/${gamePk}/quality`} target="_blank">
        {matchupSlug} {"[" + teams?.home?.team?.sport?.abbreviation + "]"}
      </a>
    </>
  );
};

export default MatchUpLink;
