import styled from "styled-components";

const Chevron = styled.i.attrs({
  className: props => {
    const { direction = "up" } = props;
    return `fa fa-chevron-${direction}`;
  }
});

export default Chevron;
