import _ from "lodash";
import { TableAggregateHealthRow, Td } from "../../SetiTable.styled";
import React from "react";
import { AggregateTd, FilteredAggregateTd } from "../ReportElements";
import { observable } from "mobx";
import trackingVersionMapping from "../../../constants/TrackingVersionMapping";

export function AggregateHealthRow({
  data,
  isPreHawkeye,
  canCreateIncident,
  groupsToShow,
  sportId,
}) {
  const trackingPerGame = data?.records?.map((info) => {
    return info["tracking"];
  });

  const gameInfoPerGame = data?.records?.map((info) => {
    return info["game"];
  });

  return (
    <TableAggregateHealthRow>
      <Td textAlignment="center" colSpan={canCreateIncident ? 5 : 4}>
        Overall Health
      </Td>
      <AggregateDataColumns
        gameInfoPerGame={gameInfoPerGame}
        trackingPerGame={trackingPerGame}
        isPreHawkeye={isPreHawkeye}
        groupsToShow={groupsToShow}
        sportId={sportId}
      />
    </TableAggregateHealthRow>
  );
}

function AggregateDataColumns({
  gameInfoPerGame,
  trackingPerGame,
  isPreHawkeye,
  groupsToShow,
  sportId,
}) {
  const gameAndTrackingArray = gameInfoPerGame.map((gameInfo, index) => ({
    ...gameInfo,
    ...trackingPerGame[index],
  }));

  const gameAndTrackingArrayWithVideoAvailability = gameAndTrackingArray.map(
    (obj) => {
      const addingBiomechAndVideo = { ...obj };

      if (obj.coachingVideo) {
        obj.coachingVideo.forEach((video) => {
          addingBiomechAndVideo[`${_.camelCase(video)}AvailableVideo`] =
            obj.totalPlays;
        });
      }

      if (
        obj.trackingVersion &&
        !trackingVersionMapping.onlyPitchToPlate.includes(
          obj.trackingVersion?.id,
        )
      ) {
        addingBiomechAndVideo["expectedSpin"] = obj.pitchesWithSpin;
      }

      if (
        obj.trackingVersion &&
        trackingVersionMapping.biomechanicsAndPose.includes(
          obj.trackingVersion?.id,
        )
      ) {
        addingBiomechAndVideo[`expectedBiomechanics`] = obj.totalPlays;
        addingBiomechAndVideo[`expectedSwings`] = obj.totalSwings;
      }

      if (
        obj.trackingVersion &&
        !trackingVersionMapping.onlyFullPitch.includes(
          obj.trackingVersion?.id,
        ) &&
        !trackingVersionMapping.onlyPitchToPlate.includes(
          obj.trackingVersion?.id,
        )
      ) {
        addingBiomechAndVideo["expectedPlayer"] = obj.totalPlays;
      }

      if (
        (obj.trackingVersion &&
          trackingVersionMapping.pose.includes(obj.trackingVersion?.id)) ||
        trackingVersionMapping.biomechanicsAndPose.includes(
          obj.trackingVersion?.id,
        )
      ) {
        addingBiomechAndVideo["expectedPose"] = obj.totalPlays;
      }

      return addingBiomechAndVideo;
    },
  );

  const filteredGameAndTrackingObj =
    gameAndTrackingArrayWithVideoAvailability.map((obj) => ({
      pitches: obj.pitches || 0,
      pitchesOpticallyTracked: obj.pitchesOpticallyTracked || 0,
      pitchesWithSpin: obj.pitchesWithSpin || 0,
      reducedSpinConfidence: obj.reducedSpinConfidence || 0,
      broadcastVideo: obj.broadcastVideo || 0,
      broadcastLDVideo: obj.broadcastLDVideo || 0,
      alternateBroadcastVideo: obj.alternateBroadcastVideo || 0,
      chHighSideviewVideo: obj.chHighSideviewVideo || 0,
      highHomeVideo: obj.highHomeVideo || 0,
      centerfieldVideo: obj.centerfieldVideo || 0,
      pitchcastVideo: obj.pitchcastVideo || 0,
      firstBasePitcherSideViewVideo: obj.firstBasePitcherSideViewVideo || 0,
      thirdBasePitcherSideViewVideo: obj.thirdBasePitcherSideViewVideo || 0,
      firstBaseBatterSideViewVideo: obj.firstBaseBatterSideViewVideo || 0,
      thirdBaseBatterSideViewVideo: obj.thirdBaseBatterSideViewVideo || 0,
      broadcastAvailableVideo: obj.broadcastAvailableVideo || 0,
      alternateBroadcastAvailableVideo:
        obj.alternateBroadcastAvailableVideo || 0,
      pitchcastAvailableVideo: obj.pitchcastAvailableVideo || 0,
      centerfieldAvailableVideo: obj.centerfieldAvailableVideo || 0,
      chhighSideviewAvailableVideo: obj.chHighSideviewAvailableVideo || 0,
      highhomeAvailableVideo: obj.highHomeAvailableVideo || 0,
      firstBaseBatterSideviewAvailableVideo:
        obj["1BBatterSideviewAvailableVideo"] || 0,
      firstBasePitcherSideviewAvailableVideo:
        obj["1BPitcherSideviewAvailableVideo"] || 0,
      thirdBaseBatterSideviewAvailableVideo:
        obj["3BBatterSideviewAvailableVideo"] || 0,
      thirdBasePitcherSideviewAvailableVideo:
        obj["3BPitcherSideviewAvailableVideo"] || 0,
      bip: obj.bip || 0,
      totalPlays: obj.totalPlays || 0,
      totalHits: obj.totalHits || 0,
      swingsTracked: obj.swingsTracked || 0,
      expectedSwings: obj.expectedSwings || 0,
      expectedBiomechanics: obj.expectedBiomechanics || 0,
      batterBiomechanicsAvailable: obj.batterBiomechanicsAvailable || 0,
      pitcherBiomechanicsAvailable: obj.pitcherBiomechanicsAvailable || 0,
      playerTrackingCompleteness: obj.playerTrackingCompleteness || 0,
      expectedSpin: obj.expectedSpin || 0,
      expectedPose: obj.expectedPose || 0,
      expectedPlayer: obj.expectedPlayer || 0,
      poseTrackingCompleteness: obj.poseTrackingCompleteness || 0,
    }));

  let trackingAggr = _.transform(
    filteredGameAndTrackingObj[0],
    (result, value, key) => {
      result[key] = 0;
    },
    {},
  );

  _.reduce(
    filteredGameAndTrackingObj,
    (trackingAggr, x) => {
      return _.forEach(
        trackingAggr,
        (value, key) => (trackingAggr[key] += x[key]),
      );
    },
    trackingAggr,
  );

  const {
    pitches,
    pitchesOpticallyTracked,
    pitchesWithSpin,
    reducedSpinConfidence,
    broadcastVideo,
    broadcastLDVideo,
    alternateBroadcastVideo,
    chHighSideviewVideo,
    highHomeVideo,
    centerfieldVideo,
    pitchcastVideo,
    firstBasePitcherSideViewVideo,
    thirdBasePitcherSideViewVideo,
    firstBaseBatterSideViewVideo,
    thirdBaseBatterSideViewVideo,
    broadcastAvailableVideo,
    alternateBroadcastAvailableVideo,
    centerfieldAvailableVideo,
    highhomeAvailableVideo,
    chhighSideviewAvailableVideo,
    pitchcastAvailableVideo,
    firstBaseBatterSideviewAvailableVideo,
    firstBasePitcherSideviewAvailableVideo,
    thirdBaseBatterSideviewAvailableVideo,
    thirdBasePitcherSideviewAvailableVideo,
    bip,
    totalPlays,
    totalHits,
    swingsTracked = 0,
    batterBiomechanicsAvailable = 0,
    pitcherBiomechanicsAvailable = 0,
    playerTrackingCompleteness = 0,
    poseTrackingCompleteness = 0,
    expectedSpin,
    expectedBiomechanics,
    expectedSwings,
    expectedPose,
    expectedPlayer,
  } = trackingAggr;

  return (
    <>
      {groupsToShow.showPitches && (
        <>
          <AggregateTd count={pitches} totalPitches={totalPlays} />
          <FilteredAggregateTd
            count={pitchesOpticallyTracked}
            totalPitches={pitches}
            filtered={!isPreHawkeye} //Column is only shown pre HawkEye
          />
          <AggregateTd count={pitchesWithSpin} totalPitches={expectedSpin} />
          <AggregateTd
            count={reducedSpinConfidence}
            totalPitches={pitches}
            ceil={true}
          />
        </>
      )}
      {groupsToShow.showVideo && (
        <>
          <AggregateTd
            count={broadcastVideo}
            totalPitches={broadcastAvailableVideo}
          />
          <AggregateTd
            count={sportId ? broadcastLDVideo : 0}
            totalPitches={sportId ? broadcastAvailableVideo : 0}
          />
          <AggregateTd
            count={alternateBroadcastVideo}
            totalPitches={alternateBroadcastAvailableVideo}
          />
          <AggregateTd
            count={pitchcastVideo}
            totalPitches={pitchcastAvailableVideo}
          />
          <AggregateTd
            count={centerfieldVideo}
            totalPitches={centerfieldAvailableVideo}
          />

          <AggregateTd
            count={isPreHawkeye ? chHighSideviewVideo : highHomeVideo}
            totalPitches={
              isPreHawkeye
                ? chhighSideviewAvailableVideo
                : highhomeAvailableVideo
            }
          />
          <AggregateTd
            count={firstBasePitcherSideViewVideo}
            totalPitches={firstBasePitcherSideviewAvailableVideo}
          />
          <AggregateTd
            count={thirdBasePitcherSideViewVideo}
            totalPitches={thirdBasePitcherSideviewAvailableVideo}
          />
          <AggregateTd
            count={firstBaseBatterSideViewVideo}
            totalPitches={firstBaseBatterSideviewAvailableVideo}
          />
          <AggregateTd
            count={thirdBaseBatterSideViewVideo}
            totalPitches={thirdBaseBatterSideviewAvailableVideo}
          />
        </>
      )}
      {groupsToShow.showHitting && (
        <>
          <AggregateTd count={bip} totalPitches={totalHits} />
          <AggregateTd count={swingsTracked} totalPitches={expectedSwings} />
        </>
      )}
      {groupsToShow.showPlayers && (
        <>
          <AggregateTd
            count={batterBiomechanicsAvailable}
            totalPitches={expectedBiomechanics}
          />
          <AggregateTd
            count={pitcherBiomechanicsAvailable}
            totalPitches={expectedBiomechanics}
          />
          <AggregateTd
            count={playerTrackingCompleteness}
            totalPitches={expectedPlayer}
          />
          <AggregateTd
            count={poseTrackingCompleteness}
            totalPitches={expectedPose}
          />
        </>
      )}
    </>
  );
}
