import IncidentTypes from "../constants/IncidentTypes";
import { RouteConstants } from "../constants/RouteConstants";
import TrackingAccuracyReportStore from "./trackingAccuracyReportStore";

class RookieTrackingAccuracyReportStore extends TrackingAccuracyReportStore {
  fetchTrackingAccuracyReport() {
    return this.veracityApi.fetchRookieTrackingAccuracyReport(this.get("date"));
  }

  cancelTrackingAccuracyReport() {
    return this.veracityApi.cancelRookieTrackingAccuracyReport();
  }

  isTrackingTabActive() {
    return this.routerStore.isRookieTrackingAccuracyReportTab;
  }

  updateNewIncidentMetadata(game) {
    return this.veracityStore.updateNewIncidentMetadata(
      IncidentTypes.ROK,
      game,
      this.get("date"),
      RouteConstants.ROOKIE_TRACKING,
    );
  }
}

export default RookieTrackingAccuracyReportStore;
