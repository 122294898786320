import React from "react";
import { inject, observer } from "mobx-react";
import moment from "moment";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import rem from "../utils/rem";
import Loading from "../components/Loading";
import MILBTrackingAccuracyReportTable from "../components/accuracyReports/MILBTrackingAccuracyReportTable";
import IncidentTypes from "../constants/IncidentTypes";
import IncidentOverviewTable from "../components/IncidentOverviewTable";
import { toast } from "react-toastify";

const Wrapper = styled.div`
  width: 1330px;
  margin: ${rem(30)};
`;

const ContentWrapper = styled.div`
  margin: ${rem(30)} 0;
`;

const DateInput = styled.input`
  text-align: center;
  line-height: ${rem(16)};
  border-radius: ${rem(4)};
  border: 1px solid #aeaeae;
  font-size: ${rem(13)};
  padding: ${rem(6)} ${rem(10)} ${rem(6)};
  cursor: pointer;
`;

const Description = styled.div`
  padding-bottom: ${rem(20)};
`;

const Footer = styled.div`
  font-size: ${rem(10)};
  padding: ${rem(10)} 0;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FlexItem = styled.div`
  align-self: flex-end;
`;

class MILBTrackingAccuracyReportContainer extends React.Component {
  constructor(props) {
    super(props);
    this._handleDateChange = this._handleDateChange.bind(this);
    this.createIncident = this.createIncident.bind(this);
  }

  _handleDateChange(date) {
    const { milbTrackingAccuracyReportSource: store, veracityStore } =
      this.props.rootStore;
    const formattedDate = date ? date.format("YYYY-MM-DD") : "";
    store.updateDate(formattedDate);
    veracityStore.fetchIncidents([IncidentTypes.MILB], formattedDate);
  }

  componentDidMount() {
    const { milbTrackingAccuracyReportSource: store, veracityStore } =
      this.props.rootStore;
    store.startPolling();
    const date = store.get("date");
    veracityStore.fetchIncidents([IncidentTypes.MILB], date);
  }

  componentWillUnmount() {
    this.props.rootStore.milbTrackingAccuracyReportSource.stopPolling();
  }

  createIncident(
    gamePk,
    name,
    status,
    description,
    severity,
    eta,
    incidentType,
    homePlateUmpire,
  ) {
    const { milbTrackingAccuracyReportSource: store, veracityStore } =
      this.props.rootStore;
    store.transitionToUpdateIncident({ gamePk });
    veracityStore
      .createIncident({
        name,
        status,
        description,
        severity,
        eta,
        homePlateUmpire,
      })
      .then((response) => {
        toast("Created incident!");
        veracityStore.fetchIncidents([IncidentTypes.MILB], store.get("date"));
      });
  }

  render() {
    const {
      milbTrackingAccuracyReportSource: store,
      milbGameActionStore,
      veracityStore,
      authStore,
    } = this.props.rootStore;
    const selectedDate = new moment(store.get("date"));

    return (
      <Wrapper>
        <FlexContainer>
          <FlexItem>
            <h1>MiLB Non-Tracking Coaching Video Report</h1>
          </FlexItem>
        </FlexContainer>
        <Description>
          A real-time pitch-by-pitch data report that monitors the overall
          quality of video systems for non-tracking Minor League Baseball games.
        </Description>
        <DatePicker
          selected={selectedDate}
          onChange={(date) => {
            this._handleDateChange(date);
            store.pushUrlParams();
          }}
          dateFormat="LL"
          customInput={<DateInput />}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
        />
        <div>
          {store.isLoading ? (
            <Loading />
          ) : (
            <ContentWrapper>
              <MILBTrackingAccuracyReportTable
                data={store.data}
                incidents={veracityStore.incidentsOverview}
                resendGame={milbGameActionStore.resendGame}
                createIncident={this.createIncident}
                hasIncidentEdit={authStore.hasIncidentEdit}
                isAdmin={authStore.isAdmin}
              />
              <Footer>Data retrieved at {store.updatedAt}</Footer>
            </ContentWrapper>
          )}
        </div>
        <IncidentOverviewTable data={veracityStore.incidentsOverview} />
      </Wrapper>
    );
  }
}

export default inject("rootStore")(
  observer(MILBTrackingAccuracyReportContainer),
);
