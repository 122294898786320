import styled from "styled-components";
import rem from "../../utils/rem";

const CheckedWrapper = styled.div`
  width: 16px;
  height: 16px;
  background-color: palevioletred;
  border: 2px solid palevioletred;
  border-radius: 2px;
`;

const Checkmark = styled.div`
  position: relative;

  &:after {
    content: "";
    display: block;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    position: absolute;
    left: 5px;
  }
`;

const ClickTarget = styled.div`
  cursor: pointer;
  display: flex;
`;

const Label = styled.div`
  color: ${props => (props.checked ? "palevioletred" : "rgba(0, 0, 0, 0.54)")};
  font-size: ${rem(14)};
  line-height: 20px;
  padding-left: 14px;
  text-align: center;
`;

const Unchecked = styled.div`
  width: 16px;
  height: 16px;
  border: 2px solid rgba(0, 0, 0, 0.54);
  border-radius: 2px;
`;

const Wrapper = styled.div`
  margin: 9px 0 9px 0;
`;

export { CheckedWrapper, Checkmark, ClickTarget, Label, Unchecked, Wrapper };
