import styled from "styled-components";
import _ from "lodash";

const Exclamation = styled.i.attrs({
  className: props => {
    const { shape } = props;
    const suffix = !_.isEmpty(shape) ? `-${shape}` : "";
    return `fa fa-exclamation${suffix}`;
  }
})`
  color: #cc0000;
`;

export default Exclamation;
