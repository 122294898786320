import React from "react";
import moment from "moment";
import { Table, Td, Tr } from "../SetiTable.styled";
import MatchupLink from "../elements/MatchupLink";
import NonMLBGameActionDropDownContainer from "../../containers/NonMLBGameActionDropDownContainer";
import { BG_COLOR, GameStatusIcon, getGameStatus } from "./ReportElements";
import { getGameState } from "../../utils/gameState";
import { FooterRows } from "./mlb/FooterRows";
import { HeaderRows } from "./mlb/HeaderRows";
import { AggregateHealthRow } from "./mlb/AggregateHealthRow";
import { DataColumns } from "./mlb/DataColumns";
import trackingVersionMapping from "../../constants/TrackingVersionMapping";

class NonMLBTrackingAccuracyReportTable extends React.Component {
  render() {
    const {
      data,
      gameStatuses,
      canCreateIncident,
      groupsToShow,
      dropDownContainer: DropDownContainer,
    } = this.props;
    const year = data.date.split("-")[0];
    const isPreHawkeye = year < 2020;
    const NON_MLB = "#FFAA33";

    const bodyRows = data.records.map((record) => {
      const { game, tracking } = record;
      const { status = {}, linescore = {} } = game;

      return (
        <Tr key={game.gamePk}>
          <Td
            textAlignment="left"
            noWrap
            //Need to add in trackingVersionMapping for system owner and use that below
            style={{
              backgroundColor:
                trackingVersionMapping.clubOwnedSystemVersion.includes(
                  game?.trackingVersion?.id,
                ) &&
                trackingVersionMapping.clubOwnedSystemOwner.includes(
                  game?.trackingVersion?.owner?.id,
                )
                  ? NON_MLB
                  : "",
            }}
          >
            <div className="flex-between">
              <MatchupLink game={game} />
              <GameStatusIcon>
                {getGameStatus(game.gamePk, gameStatuses)}
              </GameStatusIcon>
            </div>
          </Td>
          <Td textAlignment="right">{game.gamePk}</Td>
          <Td textAlignment="right" noWrap>
            {moment(game.gameDate).local().format("LT")}
          </Td>
          <Td>{getGameState(status, linescore)}</Td>
          {canCreateIncident ? (
            <Td noPadding bgcolor={BG_COLOR}>
              <DropDownContainer game={game} />
            </Td>
          ) : null}
          <DataColumns
            game={game}
            tracking={tracking}
            isPreHawkeye={isPreHawkeye}
            groupsToShow={groupsToShow}
          />
        </Tr>
      );
    });

    return (
      <Table>
        <colgroup>
          <col width="8%" />
          <col />
          <col />
          <col width="7%" />
          {canCreateIncident && <col width="8%" />}
        </colgroup>
        <thead>
          <HeaderRows
            isPreHawkeye={isPreHawkeye}
            canCreateIncident={canCreateIncident}
            groupsToShow={groupsToShow}
          />
        </thead>
        <tbody>
          {bodyRows}
          <AggregateHealthRow
            data={data}
            isPreHawkeye={isPreHawkeye}
            canCreateIncident={canCreateIncident}
            groupsToShow={groupsToShow}
          />
        </tbody>
        <tfoot>
          <FooterRows
            isPreHawkeye={isPreHawkeye}
            year={year}
            canCreateIncident={canCreateIncident}
            groupsToShow={groupsToShow}
          />
        </tfoot>
      </Table>
    );
  }
}

NonMLBTrackingAccuracyReportTable.defaultProps = {
  data: { records: [] },
};

export default NonMLBTrackingAccuracyReportTable;
