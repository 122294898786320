import TrackingAccuracyDescriptions from "../../../constants/trackingAccuracyDescriptions";
import Tooltip from "rc-tooltip";
import { PointerText, TooltipWrapper } from "../ReportElements";
import { TableHeaderRow, Th } from "../../SetiTable.styled";
import React from "react";

export function HeaderRows({ isPreHawkeye, canCreateIncident, groupsToShow }) {
  const groups = headerColumnGroups(
    isPreHawkeye,
    canCreateIncident,
    groupsToShow
  );
  return (
    <>
      <TableHeaderRow>
        {groups.map((group, idx) => GroupHeaderCell(group, idx))}
      </TableHeaderRow>
      <TableHeaderRow>
        {groups.map(group =>
          group.headerCells.map((column, idx) => HeaderCell(column, idx))
        )}
      </TableHeaderRow>
    </>
  );
}

function GroupHeaderCell(group, idx) {
  const { groupLabel, headerCells, style } = group;
  return (
    <Th key={"group-" + idx} colSpan={headerCells.length} style={style}>
      {groupLabel}
    </Th>
  );
}

function HeaderCell(column, idx) {
  const { label, description, textAlignment, colspan } = column;
  const hasDescription = !!description;
  const thContent = !hasDescription ? (
    label
  ) : (
    <Tooltip
      placement="top"
      overlay={<TooltipWrapper>{description}</TooltipWrapper>}
    >
      <PointerText>{label}</PointerText>
    </Tooltip>
  );

  return (
    <Th
      key={idx}
      textAlignment={textAlignment}
      hoverable={hasDescription}
      colSpan={colspan}
    >
      {thContent}
    </Th>
  );
}

const headerColumnGroups = (isPreHawkeye, canCreateIncident, groupsToShow) => {
  const groups = [
    {
      groupLabel: "",
      style: { background: "#ffffff", border: 0 },
      headerCells: infoHeaderCells(canCreateIncident)
    }
  ];

  if (groupsToShow.showPitches) {
    groups.push({
      groupLabel: "Pitches",
      headerCells: pitchesHeaderCells(isPreHawkeye)
    });
  }
  if (groupsToShow.showVideo) {
    groups.push({
      groupLabel: "Video",
      headerCells: [
        {
          label: "Pitches w/ Broadcast Video",
          description:
            TrackingAccuracyDescriptions.PITCHES_WITH_BROADCAST_VIDEO,
          textAlignment: "right"
        },
        {
          label: "Pitches w/ Broadcast Low Bit Rate Video",
          description:
            TrackingAccuracyDescriptions.PITCHES_WITH_LOW_BIT_RATE_BROADCAST_VIDEO,
          textAlignment: "right"
        },
        {
          label: "Pitches w/ Alternate Broadcast Video",
          description:
            TrackingAccuracyDescriptions.PITCHES_WITH_ALTERNATE_BROADCAST_VIDEO,
          textAlignment: "right"
        },
        {
          label: "Pitches w/ Pitchcast Video",
          description:
            TrackingAccuracyDescriptions.PITCHES_WITH_PITCHCAST_VIDEO,
          textAlignment: "right"
        },
        {
          label: "Pitches w/ Center Field Video",
          description:
            TrackingAccuracyDescriptions.PITCHES_WITH_CENTERFIELD_VIDEO,
          textAlignment: "right"
        },
        isPreHawkeye
          ? {
              label: "Pitches w/ CH Sideview Video",
              description:
                TrackingAccuracyDescriptions.PITCHES_WITH_CHYRON_HEGO_SIDEVIEW_VIDEO,
              textAlignment: "right"
            }
          : {
              label: "Pitches w/ High Home Video",
              description:
                TrackingAccuracyDescriptions.PITCHES_WITH_HIGH_HOME_VIDEO,
              textAlignment: "right"
            },
        {
          label: "Pitches w/ 1B Batter Side View Video",
          description:
            TrackingAccuracyDescriptions.PITCHES_WITH_FIRST_BASE_BATTER_SIDE_VIEW_VIDEO,
          textAlignment: "right"
        },
        {
          label: "Pitches w/ 3B Batter Side View Video",
          description:
            TrackingAccuracyDescriptions.PITCHES_WITH_THIRD_BASE_BATTER_SIDE_VIEW_VIDEO,
          textAlignment: "right"
        },
        {
          label: "Pitches w/ 1B Pitcher Side View Video",
          description:
            TrackingAccuracyDescriptions.PITCHES_WITH_FIRST_BASE_PITCHER_SIDE_VIEW_VIDEO,
          textAlignment: "right"
        },
        {
          label: "Pitches w/ 3B Pitcher Side View Video",
          description:
            TrackingAccuracyDescriptions.PITCHES_WITH_THIRD_BASE_PITCHER_SIDE_VIEW_VIDEO,
          textAlignment: "right"
        }
      ]
    });
  }
  if (groupsToShow.showHitting) {
    groups.push({
      groupLabel: "Hitting",
      headerCells: [
        isPreHawkeye
          ? {
              label: "BIP Tracked",
              description: TrackingAccuracyDescriptions.BIP_TRACKED,
              textAlignment: "right"
            }
          : {
              label: "BIP Tracked",
              description: TrackingAccuracyDescriptions.BIP_TRACKED_HE,
              textAlignment: "right"
            },
        {
          label: "Swings Tracked",
          description: TrackingAccuracyDescriptions.SWINGS_TRACKED,
          textAlignment: "right"
        }
      ]
    });
  }
  if (groupsToShow.showPlayers) {
    groups.push({
      groupLabel: "Players",
      headerCells: [
        {
          label: "Batter Biomech",
          description:
            TrackingAccuracyDescriptions.BATTER_BIOMECHANICS_AVAILABLE,
          textAlignment: "right"
        },
        {
          label: "Pitcher Biomech",
          description:
            TrackingAccuracyDescriptions.PITCHER_BIOMECHANICS_AVAILABLE,
          textAlignment: "right"
        },
        {
          label: "Player Tracking",
          description:
            TrackingAccuracyDescriptions.PLAYER_TRACKING_COMPLETENESS,
          textAlignment: "right"
        },
        {
          label: "Pose Tracking",
          description: TrackingAccuracyDescriptions.POSE_TRACKING_COMPLETENESS,
          textAlignment: "right"
        }
      ]
    });
  }

  return groups;
};

function infoHeaderCells(canCreateIncident) {
  return [
    {
      label: "Game",
      textAlignment: "left"
    },
    {
      label: "GamePK",
      textAlignment: "right"
    },
    {
      label: "Game Time",
      textAlignment: "right"
    },
    {
      label: "Game Status / Inning"
    },
    { label: "Actions" }
  ].filter(header => canCreateIncident || header.label !== "Actions");
}

function pitchesHeaderCells(isPreHawkeye) {
  return [
    isPreHawkeye
      ? {
          label: "Pitches Tracked",
          description: TrackingAccuracyDescriptions.PITCHES_TRACKED,
          textAlignment: "right"
        }
      : {
          label: "Pitches Tracked",
          description: TrackingAccuracyDescriptions.PITCHES_TRACKED_HE,
          textAlignment: "right"
        },
    {
      label: "Pitches Optically Tracked",
      description: TrackingAccuracyDescriptions.PITCHES_OPTICALLY_TRACKED,
      textAlignment: "right"
    },
    {
      label: "Pitches w/ Spin Rate",
      description: TrackingAccuracyDescriptions.PITCHES_WITH_SPIN_RATE,
      textAlignment: "right"
    },
    {
      label: "Pitches w/ Reduced Spin Conf.",
      description:
        TrackingAccuracyDescriptions.PITCHES_WITH_REDUCED_SPIN_CONFIDENCE,
      textAlignment: "right"
    }
  ].filter(
    header =>
      //HawkEye does not require the optical column
      isPreHawkeye ||
      header.description !==
        TrackingAccuracyDescriptions.PITCHES_OPTICALLY_TRACKED
  );
}
