const IncidentTypes = {
  SYSTEM: {
    id: 1,
    name: "SYSTEM",
  },
  MLB: {
    id: 2,
    name: "MLB",
  },
  AFL: {
    id: 3,
    name: "AFL",
  },
  ATLANTIC: {
    id: 4,
    name: "ATLANTIC",
  },
  NON_MLB: {
    id: 5,
    name: "NON MLB",
  },
  FSL: {
    id: 6,
    name: "FSL",
  },
  MILB: {
    id: 7,
    name: "MILB",
  },
  AAA: {
    id: 8,
    name: "AAA",
  },
  AA: {
    id: 9,
    name: "AA",
  },
  HighA: {
    id: 10,
    name: "HighA",
  },
  A: {
    id: 11,
    name: "A",
  },
  ROK: {
    id: 12,
    name: "Rookie",
  },
  WIN: {
    id: 13,
    name: "Winter",
  },
  Amateur: {
    id: 14,
    name: "Amateur",
  },
  InternationalIndependent: {
    id: 15,
    name: "InternationalIndependent",
  },
  SAAS: {
    id: process.env.REACT_APP_SAAS_INCIDENT_ID,
    name: process.env.REACT_APP_SASS_ORG,
  },
};

export default IncidentTypes;
