import React from "react";
import Select from "./Select";

import styled from "styled-components";

const GameActionSelect = styled(Select)`
  & * {
    background: rgb(240, 240, 240);
    color: black;
  }
`;
class GameActionsDropDown extends React.Component {
  handleChange = (e) => {
    const { game } = this.props;

    const isMlbVenue = game?.venue?.xrefIds?.some(
      (ballpark_array) =>
        ballpark_array.xrefId.includes(".mlb") ||
        ballpark_array.xrefId.includes("jup"),
    );

    switch (e.value) {
      case "create_incident":
        this.props.transitionToCreateIncident(game);
        break;
      case "clear_api_cache":
        this.props.clearStatsApiCache(game.gamePk);
        break;
      case "clear_clippy_cache":
        this.props.clearClippyCache(game.gamePk);
        break;
      case "resend_entire_game_home":
        this.props.resendGame(
          game.gamePk,
          "home",
          isMlbVenue,
          game.teams.home.team.sport.id,
        );
        break;
      case "resend_entire_game_away":
        this.props.resendGame(
          game.gamePk,
          "away",
          isMlbVenue,
          game.teams.home.team.sport.id,
        );
        break;
      case "resend_entire_game_cf":
        this.props.resendGame(
          game.gamePk,
          "cf",
          isMlbVenue,
          game.teams.home.team.sport.id,
        );
        break;
      case "resend_entire_game_network":
        this.props.resendGame(
          game.gamePk,
          "network",
          isMlbVenue,
          game.teams.home.team.sport.id,
        );
        break;
      case "resend_entire_game_hh":
        this.props.resendGame(
          game.gamePk,
          "highhome",
          isMlbVenue,
          game.teams.home.team.sport.id,
        );
        break;
      case "resend_entire_game_3b_p":
        this.props.resendGame(
          game.gamePk,
          "3b-pitcher-sideview",
          isMlbVenue,
          game.teams.home.team.sport.id,
        );
        break;
      case "resend_entire_game_3b_b":
        this.props.resendGame(
          game.gamePk,
          "3b-batter-sideview",
          isMlbVenue,
          game.teams.home.team.sport.id,
        );
        break;
      case "resend_entire_game_1b_b":
        this.props.resendGame(
          game.gamePk,
          "1b-batter-sideview",
          isMlbVenue,
          game.teams.home.team.sport.id,
        );
        break;
      case "resend_entire_game_1b_p":
        this.props.resendGame(
          game.gamePk,
          "1b-pitcher-sideview",
          isMlbVenue,
          game.teams.home.team.sport.id,
        );
        break;
      case "resend_entire_game_pitchcast":
        this.props.resendGame(
          game.gamePk,
          "CAM4",
          isMlbVenue,
          game.teams.home.team.sport.id,
        );
        break;
      default:
        break;
    }
  };

  render() {
    return (
      <GameActionSelect
        name="game-actions"
        placeholder="Actions"
        onChange={this.handleChange}
        options={this.props.gameActionOptions}
        clearable={false}
      />
    );
  }
}

export default GameActionsDropDown;
