import IncidentTypes from "../constants/IncidentTypes";
import { RouteConstants } from "../constants/RouteConstants";
import TrackingAccuracyReportStore from "./trackingAccuracyReportStore";

class SaasTrackingAccuracyReportStore extends TrackingAccuracyReportStore {
  fetchTrackingAccuracyReport() {
    return this.veracityApi.fetchSaasTrackingAccuracyReport(this.get("date"));
  }

  cancelTrackingAccuracyReport() {
    return this.veracityApi.cancelSaasTrackingAccuracyReport();
  }

  isTrackingTabActive() {
    return this.routerStore.isSaasTrackingAccuracyReportTab;
  }

  updateNewIncidentMetadata(game) {
    return this.veracityStore.updateNewIncidentMetadata(
      IncidentTypes.SAAS,
      game,
      this.get("date"),
      RouteConstants.SAAS_TRACKING,
    );
  }
}

export default SaasTrackingAccuracyReportStore;
