import _ from "lodash";
import styled from "styled-components";

const Check = styled.i.attrs({
  className: props => {
    const { shape } = props;
    const suffix = !_.isEmpty(shape) ? `-${shape}` : "";
    return `fa fa-check${suffix}`;
  }
})`
  color: #058a15;
`;

export default Check;
