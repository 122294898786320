import { action, extendObservable } from "mobx";

class TableControlsStore {
  constructor() {
    const initialState = this.getInitialState();

    extendObservable(this, {
      state: initialState,
      updateState: action((state) => {
        this.state = state;
        localStorage.setItem("tableControls", JSON.stringify(state));
      }),
    });
  }
  getInitialState() {
    const shouldShowVideoInitially = !process.env.REACT_APP_SASS_ORG;
    const defaultState = {
      showPitches: true,
      showVideo: shouldShowVideoInitially,
      showHitting: true,
      showPlayers: true,
    };
    return JSON.parse(localStorage.getItem("tableControls")) || defaultState;
  }
}

export default TableControlsStore;
