import React from "react";
import _ from "lodash";
import styled from "styled-components";
import PropTypes from "prop-types";
import Pencil from "./icons/Pencil";
import Text from "./elements/Text";

const EditIcon = styled.div`
  visibility: hidden;
  position: absolute;
  width: 20px;
  height: 100%;
  top: 0;
  right: -20px;
  background-color: #e9e9e9;
  text-align: center;
  cursor: pointer;
  font-size: 14px;

  ${Text}:hover & {
    visibility: visible;
  }
`;

class Editable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      value: props.children,
      inputDisabled: false
    };
  }

  render() {
    if (this.props.disabled) {
      return this.props.children;
    }

    if (!this.state.editing) {
      return (
        <Text
          onClick={() =>
            this.setState(state => {
              return {
                editing: !state.editing
              };
            })
          }
        >
          {this.props.children}
          <EditIcon>
            <Pencil />
          </EditIcon>
        </Text>
      );
    } else {
      return (
        <EditableText
          inputType={this.props.inputType}
          inputDisabled={this.state.inputDisabled}
          value={this.state.value}
          onChange={e => {
            this.setState({ value: e.target.value });
          }}
          handleEscape={() => {
            this.setState({
              editing: false,
              value: this.props.children
            });
          }}
          handleSave={() => {
            this.setState({
              inputDisabled: true
            });
            Promise.resolve(this.props.handleSave(this.state.value)).then(_ => {
              this.setState({
                editing: false,
                inputDisabled: false
              });
            });
          }}
        />
      );
    }
  }
}

Editable.propTypes = {
  handleSave: PropTypes.func.isRequired,
  inputType: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

Editable.defaultProps = {
  inputType: "input"
};

class EditableText extends React.Component {
  constructor(props) {
    super(props);

    this.keyListener = this.keyListener.bind(this);
    this.clickAwayListener = this.clickAwayListener.bind(this);
  }

  componentDidMount() {
    document.addEventListener("click", this.clickAwayListener);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.clickAwayListener);
  }

  clickAwayListener(e) {
    // Make sure that we didn't click the input itself.
    if (this.ref && !this.ref.contains(e.target)) {
      this.props.handleSave();
    }
  }

  keyListener(e) {
    switch (e.keyCode) {
      case 27: // ESC
        this.props.handleEscape();
        break;
      default:
        break;
    }
  }

  render() {
    const { inputType: Input, inputDisabled } = this.props;
    const additionalProps = _.omitBy(
      {
        rows: Input === "textarea" && 10
      },
      value => !value
    );

    return (
      <div ref={node => (this.ref = node)}>
        <Input
          style={{ fontSize: "inherit", width: "100%" }}
          value={this.props.value}
          onChange={this.props.onChange}
          onKeyDown={this.keyListener}
          disabled={inputDisabled}
          {...additionalProps}
        />
      </div>
    );
  }
}

export default Editable;
