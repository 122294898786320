import _ from "lodash";
import { action, extendObservable, computed } from "mobx";

class FormStore {
  constructor(routerStore) {
    this.updateCache = {};
    this.defaults = {};

    extendObservable(this, {
      formValues: {},
      update: key => {
        // Memoize the update actions
        if (!this.updateCache.hasOwnProperty(key)) {
          this.updateCache[key] = action(
            value => (this.formValues[key] = value)
          );
        }
        return this.updateCache[key];
      },
      get: key => this.formValues[key],
      urlParams: computed(() => {
        return _.chain(this.formValues)
          .keys()
          .filter(param => this.formValues[param] !== this.defaults[param])
          .map(
            param =>
              `${param}=${
                Array.isArray(this.formValues[param])
                  ? this.formValues[param].join(",")
                  : this.formValues[param]
              }`
          )
          .join("&")
          .value();
      }),
      pushUrlParams: action(() => {
        routerStore.history.push({
          pathname: routerStore.location.pathname,
          search: this.urlParams
        });
      }),
      updateFromUrlParams: action(search => {
        const params = new URLSearchParams(search);
        _.chain(this.formValues)
          .keys()
          .forEach(key => {
            this.formValues[key] = params.has(key)
              ? params.get(key)
              : this.defaults[key];
          })
          .value();
      })
    });
  }
}

export default FormStore;
