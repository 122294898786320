import React from "react";
import { inject, observer } from "mobx-react";
import gameActionOptions from "../constants/gameActionOptions";
import GameActionsDropDown from "../components/GameActionsDropDown";

class NonMLBGameActionDropDownContainer extends React.Component {
  getGameActionStore() {
    const { rootStore } = this.props;
    const sportId = this.props.game.teams.home.team.sport.id;

    switch (sportId) {
      case 11:
        return rootStore.AAAGameActionStore;
      case 12:
        return rootStore.AAGameActionStore;
      case 13:
        return rootStore.HighAGameActionStore;
      case 14:
        return rootStore.AGameActionStore;
      case 16:
        return rootStore.RookieGameActionStore;
      case 586:
      case 22:
        return rootStore.AmateurGameActionStore;
      case 17:
        return rootStore.WinterGameActionStore;
      case 31:
      case 32:
      case 23:
      case 6005:
      case 510:
      case 509:
      case 51:
      case 61:
        return rootStore.InternationalIndependentGameActionStore;
      case 21: 
      default:
        return rootStore.nonMLBGameActionStore;
    }
  }

  render() {
    const { rootStore } = this.props;
    const gameActionStore = this.getGameActionStore();

    if (!gameActionStore) {
      return <div>Error: Unsupported sport ID</div>;
    }

    const nonMlbStore = rootStore.nonMLBGameActionStore;
    gameActionStore.trackingAccuracyReportSource.update("date")(
      nonMlbStore.trackingAccuracyReportSource.get("date"),
    );

    return (
      <GameActionsDropDown
        game={this.props.game}
        gameActionOptions={gameActionOptions}
        transitionToCreateIncident={
          gameActionStore.trackingAccuracyReportSource
            .transitionToCreateIncident
        }
        clearClippyCache={gameActionStore.clearClippyCache}
        clearStatsApiCache={gameActionStore.clearStatsApiCache}
        resendGame={gameActionStore.resendGame}
      />
    );
  }
}

export default inject("rootStore")(observer(NonMLBGameActionDropDownContainer));
