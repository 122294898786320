import React from "react";
import { inject, observer } from "mobx-react";
import IncidentTypes from "../../constants/IncidentTypes";
import BaseMLBTrackingAccuracyReportContainer from "../BaseMLBTrackingAccuracyReportContainer";
import SaasTrackingAccuracyReportTable from "../../components/accuracyReports/saas/SaasTrackingAccuracyReportTable";

const org = process.env.REACT_APP_SASS_ORG;

class SaasTrackingAccuracyReportContainer extends React.Component {
  render() {
    const rootStore = this.props.rootStore;
    const description = `A real-time pitch-by-pitch data report that monitors the overall quality of data and video systems used for ${org.toUpperCase()} games.`;
    const reportTable = (
      <SaasTrackingAccuracyReportTable
        data={rootStore.SaasGameActionStore.trackingAccuracyReportSource.data}
        gameStatuses={rootStore.veracityStore.gameStatuses}
        transitionToCreateIncident={
          rootStore.SaasGameActionStore.trackingAccuracyReportSource
            .transitionToCreateIncident
        }
        canCreateIncident={true}
        groupsToShow={rootStore.tableControlsStore.state}
      />
    );
    return (
      <BaseMLBTrackingAccuracyReportContainer
        header={`${org.toUpperCase()} Data Report`}
        description={description}
        reportSource={
          rootStore.SaasGameActionStore.trackingAccuracyReportSource
        }
        reportTable={reportTable}
        incidentTypesToFetch={[IncidentTypes.SAAS, IncidentTypes.SYSTEM]}
        showSystemIncidentButton={rootStore.authStore.hasIncidentEdit}
      />
    );
  }
}

export default inject("rootStore")(
  observer(SaasTrackingAccuracyReportContainer),
);
