import React, { Component } from "react";
import OktaSignInWidget from "../components/OktaSignInWidget";
import { inject, observer } from "mobx-react";
import bg from "../assets/mlb-okta-bg.jpeg";
import styled from "styled-components";

const Background = styled.div`
  position: absolute;
  background-image: url(${bg});
  background-size: cover;
  height: 100vh;
  width: 100vw;
`;

class OktaLogin extends Component {
  render() {
    const { onSuccess, onError } = this.props.rootStore.authStore;
    return (
      <Background>
        <OktaSignInWidget
          baseUrl={this.props.baseUrl}
          onSuccess={onSuccess}
          onError={onError}
        />
      </Background>
    );
  }
}

export default inject("rootStore")(observer(OktaLogin));
