import styled from "styled-components";
import rem from "../../utils/rem";

const Table = styled.table`
  table-layout: auto;
  border-collapse: collapse;

  & th {
    text-align: left;
    border-bottom: 1px solid #333;
    padding: ${rem(3)} ${rem(10)};
  }

  & td {
    vertical-align: top;
    padding: ${rem(10)};
  }
`;

const Th = styled.th`
  color: ${props => (props.strong ? "#333" : "#999")};
`;

const Tr = styled.tr``;

const Td = styled.td``;

export { Table, Tr, Td, Th };
