import IncidentTypes from "../constants/IncidentTypes";
import { RouteConstants } from "../constants/RouteConstants";
import TrackingAccuracyReportStore from "./trackingAccuracyReportStore";

class MLBTrackingAccuracyReportStore extends TrackingAccuracyReportStore {
  fetchTrackingAccuracyReport() {
    return this.veracityApi.fetchTrackingMLBAccuracyReport(this.get("date"));
  }

  cancelTrackingAccuracyReport() {
    return this.veracityApi.cancelTrackingMLBAccuracyReport();
  }

  isTrackingTabActive() {
    return this.routerStore.isMLBTrackingAccuracyReportTab;
  }

  updateNewIncidentMetadata(game) {
    return this.veracityStore.updateNewIncidentMetadata(
      IncidentTypes.MLB,
      game,
      this.get("date"),
      RouteConstants.BASE,
    );
  }
}

export default MLBTrackingAccuracyReportStore;
