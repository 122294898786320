import { Td, Tr } from "../../SetiTable.styled";
import { CRITICAL_COLOR, WARN_COLOR } from "../ReportElements";
import React from "react";

export function FooterRows({
  isPreHawkeye,
  year,
  canCreateIncident,
  groupsToShow,
}) {
  //optical column only shows pre Hawkeye
  let opticalWarn;
  let opticalAlert;
  if (isPreHawkeye) {
    opticalWarn = <Td bgcolor={WARN_COLOR}>97% to 98% {year}</Td>;
    opticalAlert = <Td bgcolor={CRITICAL_COLOR}>{"< 97%"}</Td>;
  }
  let bipWarn;
  let bipAlert;
  if (isPreHawkeye) {
    bipWarn = "80% to 90%";
    bipAlert = "< 80%";
  } else {
    bipWarn = "95% to 99.999%";
    bipAlert = "<95%";
  }
  const numColumns = getNumColumns(
    canCreateIncident,
    groupsToShow,
    isPreHawkeye,
  );

  return (
    <>
      <Tr>
        <td colSpan={numColumns}>&#8203;</td>
      </Tr>
      <Tr>
        <Td noborder />
        <Td noborder />
        <Td noborder />
        <Td noborder />
        {canCreateIncident && <Td noborder />}
        {groupsToShow.showPitches && (
          <>
            <Td bgcolor={WARN_COLOR}>99% to 99.5%</Td>
            {opticalWarn}
            <Td bgcolor={WARN_COLOR}>90% to 95%</Td>
            <Td bgcolor={WARN_COLOR}>0.1% to 1%</Td>
          </>
        )}
        {groupsToShow.showVideo && (
          <Td bgcolor={WARN_COLOR} colSpan={10}>
            99.5% to 99.9999%
          </Td>
        )}
        {groupsToShow.showHitting && (
          <>
            <Td bgcolor={WARN_COLOR}>{bipWarn}</Td>
            <Td bgcolor={WARN_COLOR}>90% to 95%</Td>
          </>
        )}
        {groupsToShow.showPlayers && (
          <>
            <Td bgcolor={WARN_COLOR} colSpan={4}>
              90% to 95%
            </Td>
          </>
        )}
      </Tr>
      <Tr>
        <Td noborder />
        <Td noborder />
        <Td noborder />
        <Td noborder />
        {canCreateIncident && <Td noborder />}
        {groupsToShow.showPitches && (
          <>
            <Td bgcolor={CRITICAL_COLOR}>{"< 99%"}</Td>
            {opticalAlert}
            <Td bgcolor={CRITICAL_COLOR}>{"< 90%"}</Td>
            <Td bgcolor={CRITICAL_COLOR}>{"> 1%"}</Td>
          </>
        )}
        {groupsToShow.showVideo && (
          <Td bgcolor={CRITICAL_COLOR} colSpan={10}>
            {"< 99.5%"}
          </Td>
        )}
        {groupsToShow.showHitting && (
          <>
            <Td bgcolor={CRITICAL_COLOR}>{bipAlert}</Td>
            <Td bgcolor={CRITICAL_COLOR}>{"< 90%"}</Td>
          </>
        )}
        {groupsToShow.showPlayers && (
          <>
            <Td bgcolor={CRITICAL_COLOR} colSpan={4}>
              {"< 90%"}
            </Td>
          </>
        )}
      </Tr>
    </>
  );
}

function getNumColumns(canCreateIncident, groupsToShow, isPreHawkeye) {
  let numColumns = 4;
  numColumns += canCreateIncident ? 1 : 0;
  numColumns += groupsToShow.showPitches ? (isPreHawkeye ? 4 : 3) : 0;
  numColumns += groupsToShow.showVideo ? 10 : 0;
  numColumns += groupsToShow.showHitting ? 2 : 0;
  numColumns += groupsToShow.showPlayers ? 2 : 0;
  return numColumns;
}
