const coachingVideoMapping = {
  broadcast: "BROADCAST",
  lowDefBroadcast: "LDBroadcast",
  alternateBroadcast: "ALTERNATE_BROADCAST",
  pitchcast: "PITCHCAST",
  centerfield: "CENTERFIELD",
  chHighSideview: "CH_HIGH_SIDEVIEW",
  highHome: "HIGH_HOME",
  firstBaseBatterSideview: "1B_BATTER_SIDEVIEW",
  thirdBaseBatterSideview: "3B_BATTER_SIDEVIEW",
  firstBasePitcherSideview: "1B_PITCHER_SIDEVIEW",
  thirdBasePitcherSideview: "3B_PITCHER_SIDEVIEW",
};

export default coachingVideoMapping;
