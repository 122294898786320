import React from "react";
import { inject, observer } from "mobx-react";
import gameActionOptions from "../constants/gameActionOptions";
import GameActionsDropDown from "../components/GameActionsDropDown";

class AGameActionDropDownContainer extends React.Component {
  render() {
    const { AGameActionStore } = this.props.rootStore;

    return (
      <GameActionsDropDown
        game={this.props.game}
        gameActionOptions={gameActionOptions}
        transitionToCreateIncident={
          AGameActionStore.trackingAccuracyReportSource
            .transitionToCreateIncident
        }
        clearClippyCache={AGameActionStore.clearClippyCache}
        clearStatsApiCache={AGameActionStore.clearStatsApiCache}
        resendGame={AGameActionStore.resendGame}
      />
    );
  }
}

export default inject("rootStore")(observer(AGameActionDropDownContainer));
