import { FormControlLabel, Switch } from "@material-ui/core";

export function TableControls({ groupsToShow, updateGroupsToShow }) {
  const handleChange = event => {
    updateGroupsToShow({
      ...groupsToShow,
      [event.target.name]: event.target.checked
    });
  };

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            name="showPitches"
            color="primary"
            checked={groupsToShow.showPitches}
            onChange={handleChange}
          />
        }
        label="Pitches"
      />
      <FormControlLabel
        control={
          <Switch
            name="showVideo"
            color="primary"
            checked={groupsToShow.showVideo}
            onChange={handleChange}
          />
        }
        label="Video"
      />
      <FormControlLabel
        control={
          <Switch
            name="showHitting"
            color="primary"
            checked={groupsToShow.showHitting}
            onChange={handleChange}
          />
        }
        label="Hitting"
      />
      <FormControlLabel
        control={
          <Switch
            name="showPlayers"
            color="primary"
            checked={groupsToShow.showPlayers}
            onChange={handleChange}
          />
        }
        label="Players"
      />
    </>
  );
}
