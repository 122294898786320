import React from "react";
import Button from "./elements/Button";
import styled from "styled-components";

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const YesButton = styled(Button)`
  margin-right: 20px;
`;

const VerifyDeleteModal = props => {
  return (
    <div>
      <p>{props.message}</p>
      <FlexContainer>
        <YesButton onClick={props.handleDelete}>Yes</YesButton>
        <Button onClick={props.toggleModal}>No</Button>
      </FlexContainer>
    </div>
  );
};

export default VerifyDeleteModal;
