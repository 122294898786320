import styled from "styled-components";
import _ from "lodash";

const Resend = styled.i.attrs({
  className: props => {
    const { shape } = props;
    const suffix = !_.isEmpty(shape) ? `-${shape}` : "";
    return `fa fa-repeat${suffix}`;
  }
})`
  &:hover {
    cursor: pointer;
  }
  font-size: 1.3em;
`;

export default Resend;
