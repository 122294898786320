import styled from "styled-components";
import rem from "../utils/rem";

const Table = styled.table`
  border-collapse: collapse;
  font-size: ${rem(12)};
  table-layout: fixed;
  width: ${(props) => props.width};

  & tr td,
  th {
    padding: ${(props) => (props.compact ? rem(3) : rem(10))};
  }
`;

const Td = styled.td`
  background-color: ${(props) => props.bgcolor};
  font-weight: ${(props) => props.fontWeight};
  border: ${(props) =>
    props.noborder ? null : "1px solid rgb(210, 210, 210)"};
  text-align: ${(props) => props.textAlignment || "center"};
  padding: ${(props) => props.noPadding && "0 !important"};
  white-space: ${(props) => (props.noWrap ? "nowrap" : "normal")};

  .flex-between {
    display: flex;
    justify-content: space-between;
  }
`;

const TdMissingWithTooltip = Td.extend`
  position: relative;

  &:hover {
    background-color: ${(props) => props.highlight && "transparent"};
  }

  &:hover:after {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 3px;
    content: attr(data-total-pitches-tooltip);
    color: #eee;
    font-size: ${rem(10)};
    left: 50%;
    top: 30%;
    padding: 1px 2px;
    position: absolute;
    transform: translate(-50%, -100%);
    width: 50px;
    text-align: center;
    z-index: 10;
  }

  &::before {
    content: "x";
    color: black; /* Transparent black */
    font-size: 17px; /* Bigger size */
    position: absolute;
    top: 50%; /* Center vertically */
    right: 50%; /* Center horizontally */
    transform: translate(50%, -50%); /* Adjust for centering */
    pointer-events: none; /* Ensure the "x" doesn't interfere with hover events */
  }
`;

const TdWithTooltip = Td.extend`
  position: relative;

  &:hover {
    background-color: ${(props) => props.highlight && "transparent"};
  }

  &:hover:after {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 3px;
    content: attr(data-total-pitches-tooltip);
    color: #eee;
    font-size: ${rem(10)};
    left: 50%;
    top: 30%;
    padding: 1px 2px;
    position: absolute;
    transform: translate(-50%, -100%);
    width: 75px;
    text-align: center;
    z-index: 10;
  }
`;

const Th = styled.th`
  border: 1px solid rgb(210, 210, 210);
  font-weight: 500;
  font-size: ${rem(11)};
  text-align: ${(props) => props.textAlignment || "center"};
  text-decoration: ${(props) => props.hoverable && "underline dotted"};
`;

const TableHeaderRow = styled.tr`
  background-color: ${(props) => props.theme.lightGray};
`;

const TableAggregateHealthRow = styled.tr`
  & td:first-child {
    font-weight: 500;
  }
`;

const HeaderGrouping = styled.th`
  background-color: ${(props) => props.backgroundColor};
  padding: 5px 0;
  text-transform: uppercase;
`;

const Tr = styled.tr``;

export {
  Table,
  Td,
  TdWithTooltip,
  TdMissingWithTooltip,
  Th,
  TableHeaderRow,
  TableAggregateHealthRow,
  HeaderGrouping,
  Tr,
};
