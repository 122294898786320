import styled from "styled-components";

const Button = styled.button`
  background: ${props => {
    if (props.isPrimary) return props.theme.primary;
    else return "#f1f1f1";
  }};
  color: ${props => {
    if (props.isPrimary) return "#fdfdfd";
    else return "#666";
  }};

  border: ${props => {
    if (props.isPrimary) return "1px solid transparent;";
    else return "solid 0.5px #e2e2e2;";
  }};

  border-radius: 3px;
  padding: ${props => (props.compact ? "4px" : "8px 16px")};
  cursor: pointer;

  &:hover {
    filter: brightness(95%);
  }

  &:active {
    filter: brightness(90%);
  }
`;

export default Button;
