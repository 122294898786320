export const getMatchupSlug = (game = {}, fullNames = false) => {
  const { teams = {} } = game;
  const { home = {}, away = {} } = teams;
  const { team: homeTeam = {} } = home;
  const { team: awayTeam = {} } = away;

  return fullNames
    ? `${awayTeam.name} @ ${homeTeam.name}`
    : `${awayTeam.abbreviation} @ ${homeTeam.abbreviation}`;
};
