import _ from "lodash";
import { action, reaction, computed, extendObservable } from "mobx";
import moment from "moment-timezone";
import FormStore from "./formStore";
import IncidentTypes from "../constants/IncidentTypes";
import { RouteConstants } from "../constants/RouteConstants";

const POLLING_INTERVAL_MS = 10000;

class MlbTrackingAccuracyReportStore extends FormStore {
  constructor(veracityApi, routerStore, veracityStore) {
    super(routerStore);

    this.veracityApi = veracityApi;
    this.routerStore = routerStore;
    this.veracityStore = veracityStore;

    // Current time in ET
    const now = moment().tz("America/New_York");
    this.defaults = {
      date: (now.hour() < 10 ? now.subtract(1, "day") : now).format(
        "YYYY-MM-DD"
      )
    };

    extendObservable(this, {
      isLoading: computed(() => _.isEmpty(this.data) && this.isFetching),
      isFetching: true,
      updatedAt: "",
      data: undefined,
      formValues: {
        date: this.defaults["date"]
      },
      fetchData: action(() => {
        this.isFetching = true;
        veracityApi
          .fetchTrackingMILBAccuracyReport(this.get("date"))
          .then(data => {
            this.data = data;
            this.isFetching = false;
            this.updatedAt = moment().format("LTS");
          })
          .catch(e => {
            this.isFetching = false;
          });
      }),
      startPolling: () => {
        this.poller = setInterval(() => {
          // Only fetch data if we are looking at today's games.
          if (this.get("date") === moment().format("YYYY-MM-DD")) {
            this.fetchData();
          }
        }, POLLING_INTERVAL_MS);
      },
      stopPolling: () => {
        clearInterval(this.poller);
      },
      updateDate: action(date => {
        this.update("date")(date);
        veracityApi.cancelTrackingMILBAccuracyReport();
        this.data = undefined;
      })
    });

    reaction(
      () =>
        this.routerStore.isMILBTrackingAccuracyReportTab &&
        this.routerStore.location,
      () => {
        if (this.routerStore.isMILBTrackingAccuracyReportTab) {
          this.updateFromUrlParams(this.routerStore.location.search);
          this.fetchData();
        }
      },
      true
    );
  }

  transitionToUpdateIncident = game => {
    this.veracityStore.updateNewIncidentMetadata(
      IncidentTypes.MILB,
      game,
      this.get("date"),
      RouteConstants.MILB_TRACKING
    );
  };
}

export default MlbTrackingAccuracyReportStore;
