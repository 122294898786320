import authClient from "../httpClients/authClient";
import axios from "axios";

const CancelToken = axios.CancelToken;
const BASE_URL = process.env.REACT_APP_VERACITY_URL;

class VeracityApi {
  fetchTrackingMLBAccuracyReport = (date) => {
    this.trackingMLBAccuracyReportSource = CancelToken.source();
    return authClient
      .get(`${BASE_URL}/trackingAccuracy/mlb/reports?date=${date}`, {
        cancelToken: this.trackingMLBAccuracyReportSource.token,
      })
      .then((response) => response.data);
  };

  cancelTrackingMLBAccuracyReport = () =>
    this.trackingMLBAccuracyReportSource.cancel();

  fetchTrackingMILBAccuracyReport = (date) => {
    this.trackingMILBAccuracyReportSource = CancelToken.source();
    return authClient
      .get(`${BASE_URL}/trackingAccuracy/milb/reports?date=${date}`, {
        cancelToken: this.trackingMILBAccuracyReportSource.token,
      })
      .then((response) => response.data);
  };

  cancelTrackingMILBAccuracyReport = () =>
    this.trackingMILBAccuracyReportSource.cancel();

  fetchTrackingAtlanticAccuracyReport = (date) => {
    this.trackingAtlanticAccuracyReportSource = CancelToken.source();
    return authClient
      .get(`${BASE_URL}/trackingAccuracy/atlantic/reports?date=${date}`, {
        cancelToken: this.trackingAtlanticAccuracyReportSource.token,
      })
      .then((response) => response.data);
  };

  cancelTrackingAtlanticAccuracyReport = () =>
    this.trackingAtlanticAccuracyReportSource.cancel();

  fetchTrackingFSLAccuracyReport = (date) => {
    this.trackingFSLAccuracyReportSource = CancelToken.source();
    return authClient
      .get(`${BASE_URL}/trackingAccuracy/fsl/reports?date=${date}`, {
        cancelToken: this.trackingFSLAccuracyReportSource.token,
      })
      .then((response) => response.data);
  };

  cancelTrackingFSLAccuracyReport = () =>
    this.trackingFSLAccuracyReportSource.cancel();

  fetchTrackingAFLAccuracyReport = (date) => {
    this.trackingAFLAccuracyReportSource = CancelToken.source();
    return authClient
      .get(`${BASE_URL}/trackingAccuracy/afl/reports?date=${date}`, {
        cancelToken: this.trackingAFLAccuracyReportSource.token,
      })
      .then((response) => response.data);
  };

  cancelTrackingAFLAccuracyReport = () =>
    this.trackingAFLAccuracyReportSource.cancel();

  fetchTrackingNonMLBAccuracyReport = (date) => {
    this.trackingNonMLBAccuracyReportSource = CancelToken.source();
    return authClient
      .get(`${BASE_URL}/trackingAccuracy/nonmlb/reports?date=${date}`, {
        cancelToken: this.trackingNonMLBAccuracyReportSource.token,
      })
      .then((response) => response.data);
  };

  cancelTrackingNonMLBAccuracyReport = () =>
    this.trackingNonMLBAccuracyReportSource.cancel();

  fetchAAATrackingAccuracyReport = (date) => {
    this.aaaTrackingAccuracyReportSource = CancelToken.source();
    return authClient
      .get(`${BASE_URL}/trackingAccuracy/aaa/reports?date=${date}`, {
        cancelToken: this.aaaTrackingAccuracyReportSource.token,
      })
      .then((response) => response.data);
  };

  cancelAAATrackingAccuracyReport = () =>
    this.aaaTrackingAccuracyReportSource.cancel();

  fetchAATrackingAccuracyReport = (date) => {
    this.aaTrackingAccuracyReportSource = CancelToken.source();
    return authClient
      .get(`${BASE_URL}/trackingAccuracy/aa/reports?date=${date}`, {
        cancelToken: this.aaTrackingAccuracyReportSource.token,
      })
      .then((response) => response.data);
  };

  cancelAATrackingAccuracyReport = () =>
    this.aaTrackingAccuracyReportSource.cancel();

  fetchHighATrackingAccuracyReport = (date) => {
    this.highaTrackingAccuracyReportSource = CancelToken.source();
    return authClient
      .get(`${BASE_URL}/trackingAccuracy/high-a/reports?date=${date}`, {
        cancelToken: this.highaTrackingAccuracyReportSource.token,
      })
      .then((response) => response.data);
  };

  cancelHighATrackingAccuracyReport = () =>
    this.highaTrackingAccuracyReportSource.cancel();

  fetchATrackingAccuracyReport = (date) => {
    this.aTrackingAccuracyReportSource = CancelToken.source();
    return authClient
      .get(`${BASE_URL}/trackingAccuracy/a/reports?date=${date}`, {
        cancelToken: this.aTrackingAccuracyReportSource.token,
      })
      .then((response) => response.data);
  };

  cancelATrackingAccuracyReport = () =>
    this.aTrackingAccuracyReportSource.cancel();

  fetchRookieTrackingAccuracyReport = (date) => {
    this.rookieTrackingAccuracyReportSource = CancelToken.source();
    return authClient
      .get(`${BASE_URL}/trackingAccuracy/rookie/reports?date=${date}`, {
        cancelToken: this.rookieTrackingAccuracyReportSource.token,
      })
      .then((response) => response.data);
  };

  cancelRookieTrackingAccuracyReport = () =>
    this.rookieTrackingAccuracyReportSource.cancel();

  fetchAmateurTrackingAccuracyReport = (date) => {
    this.amateurTrackingAccuracyReportSource = CancelToken.source();
    return authClient
      .get(`${BASE_URL}/trackingAccuracy/amateur/reports?date=${date}`, {
        cancelToken: this.amateurTrackingAccuracyReportSource.token,
      })
      .then((response) => response.data);
  };

  cancelAmateurTrackingAccuracyReport = () =>
    this.amateurTrackingAccuracyReportSource.cancel();

  fetchWinterTrackingAccuracyReport = (date) => {
    this.winterTrackingAccuracyReportSource = CancelToken.source();
    return authClient
      .get(`${BASE_URL}/trackingAccuracy/winter/reports?date=${date}`, {
        cancelToken: this.winterTrackingAccuracyReportSource.token,
      })
      .then((response) => response.data);
  };

  cancelWinterTrackingAccuracyReport = () =>
    this.winterTrackingAccuracyReportSource.cancel();

  fetchInternationalIndependentTrackingAccuracyReport = (date) => {
    this.internationalIndependentTrackingAccuracyReportSource =
      CancelToken.source();
    return authClient
      .get(
        `${BASE_URL}/trackingAccuracy/independent-international/reports?date=${date}`,
        {
          cancelToken:
            this.internationalIndependentTrackingAccuracyReportSource.token,
        },
      )
      .then((response) => response.data);
  };

  cancelInternationalIndependentTrackingAccuracyReport = () =>
    this.internationalIndependentTrackingAccuracyReportSource.cancel();

  fetchSaasTrackingAccuracyReport = (date) => {
    const org = process.env.REACT_APP_SASS_ORG;
    this.saasTrackingAccuracyReportSource = CancelToken.source();
    return authClient
      .get(`${BASE_URL}/trackingAccuracy/${org}/reports?date=${date}`, {
        cancelToken: this.saasTrackingAccuracyReportSource.token,
      })
      .then((response) => response.data);
  };

  cancelSaasTrackingAccuracyReport = () =>
    this.saasTrackingAccuracyReportSource.cancel();

  clearCache = (gamePk) => {
    return authClient
      .post(`${BASE_URL}/statsApi/cache/refresh`, { gamePk })
      .then((response) => response.data);
  };

  fetchIncidents = (incidentTypes, date) => {
    const incidentTypeQuery = incidentTypes
      .map((incidentType) => `&incidentTypeId=${incidentType.id}`)
      .join("");
    return authClient
      .get(`${BASE_URL}/incidents?date=${date}${incidentTypeQuery}`)
      .then((response) => response.data);
  };

  fetchRecentIncidents = (
    incidentTypeIds,
    updateDate,
    status,
    severity,
    owner,
    page,
    limit,
  ) => {
    const queryParams = [
      ["lastUpdatedDateTime", updateDate],
      ["limit", limit],
      ["page", page],
    ];

    incidentTypeIds.forEach((id) => queryParams.push(["incidentTypeId", id]));

    if (status) {
      queryParams.push(["status", status]);
    }

    if (severity) {
      queryParams.push(["severity", severity]);
    }

    if (owner) {
      queryParams.push(["owner", owner]);
    }

    return authClient
      .get(
        `${BASE_URL}/incidentsUpdatedSince?${new URLSearchParams(
          queryParams,
        ).toString()}`,
      )
      .then((response) => response.data);
  };

  fetchIncident = (incidentId) => {
    return authClient
      .get(`${BASE_URL}/incidents/${incidentId}`)
      .then((response) => response.data);
  };

  createIncident = (incident) => {
    return authClient
      .post(`${BASE_URL}/incidents`, incident)
      .then((response) => response.data);
  };

  createIncidentUpdate = (incidentId, incidentUpdate) => {
    return authClient
      .post(`${BASE_URL}/incidents/${incidentId}/updates`, incidentUpdate)
      .then((response) => response.data);
  };

  updateIncident = (incidentId, incident) => {
    return authClient
      .put(`${BASE_URL}/incidents/${incidentId}`, incident)
      .then((response) => response.data);
  };

  updateIncidentUpdate = (incidentId, incidentUpdateId, incidentUpdate) => {
    return authClient
      .put(
        `${BASE_URL}/incidents/${incidentId}/updates/${incidentUpdateId}`,
        incidentUpdate,
      )
      .then((response) => response.data);
  };

  deleteIncident = (incidentId) => {
    return authClient
      .delete(`${BASE_URL}/incidents/${incidentId}`)
      .then((response) => response.data);
  };

  deleteIncidentUpdate = (incidentId, incidentUpdateId) => {
    return authClient
      .delete(`${BASE_URL}/incidents/${incidentId}/updates/${incidentUpdateId}`)
      .then((response) => response.data);
  };

  fetchGameNotes = (gamePks) => {
    const query = "?gamePk=" + gamePks.join("&gamePk=");
    return authClient
      .get(`${BASE_URL}/gameNote${query}`)
      .then((response) => response.data);
  };

  createGameNote = (gameNote) => {
    return authClient
      .post(`${BASE_URL}/gameNote`, gameNote)
      .then((response) => response.data);
  };

  updateGameNote = (gameNote) => {
    return authClient
      .put(`${BASE_URL}/gameNote/${gameNote.gamePk}`, gameNote)
      .then((response) => response.data);
  };
}

export default VeracityApi;
