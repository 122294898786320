export default {
  PITCHES_TRACKED:
    "Percentage of pitches thrown where pitch-tracking data was captured",
  PITCHES_TRACKED_HE:
    "Percentage of pitches thrown where Hawkeye captured pitch-tracking data",
  PITCHES_OPTICALLY_TRACKED:
    "Percentage of Pitches Tracked using optical tracking.",
  PITCHES_WITH_BROADCAST_VIDEO:
    "Pitches with an associated broadcast video clip",
  PITCHES_WITH_LOW_BIT_RATE_BROADCAST_VIDEO:
    "Pitches with an associated lower bit rate broadcast video clip",
  PITCHES_WITH_ALTERNATE_BROADCAST_VIDEO:
    "Pitches with an associated alternate broadcast video clip",
  PITCHES_WITH_CHYRON_HEGO_SIDEVIEW_VIDEO:
    "Pitches with an associated side view video clip from ChyronHego",
  PITCHES_WITH_HIGH_HOME_VIDEO:
    "Pitches with an associated high home video clip",
  PITCHES_WITH_CENTERFIELD_VIDEO: "Pitches with an associated CF camera video",
  PITCHES_WITH_PITCHCAST_VIDEO:
    "Pitches with an associated pitchcast camera video",
  PITCHES_WITH_FIRST_BASE_PITCHER_SIDE_VIEW_VIDEO:
    "Pitches w/ 1B Pitcher Side View Video",
  PITCHES_WITH_THIRD_BASE_PITCHER_SIDE_VIEW_VIDEO:
    "Pitches w/ 3B Pitcher Side View Video",
  PITCHES_WITH_FIRST_BASE_BATTER_SIDE_VIEW_VIDEO:
    "Pitches w/ 1B Batter Side View Video",
  PITCHES_WITH_THIRD_BASE_BATTER_SIDE_VIEW_VIDEO:
    "Pitches w/ 3B Batter Side View Video",
  PITCHES_WITH_SPIN_RATE:
    "Percentage of pitches tracked where spin information was captured",
  PITCHES_WITH_REDUCED_SPIN_CONFIDENCE:
    "Percentage of pitches with reduced spin rate confidence",
  BIP_TRACKED: "Percentage of balls in play that were tracked.",
  BIP_TRACKED_HE: "Percentage of balls in play that were tracked by Hawkeye",
  SWINGS_TRACKED: "Percentage of swings with bat tracking data",
  BATTER_BIOMECHANICS_AVAILABLE:
    "Percentage of plays with batter biomechanics data",
  PITCHER_BIOMECHANICS_AVAILABLE:
    "Percentage of plays with pitcher biomechanics data",
  PLAYER_TRACKING_COMPLETENESS:
    "Percentage of plays with complete player tracking",
  POSE_TRACKING_COMPLETENESS: "Percentage of plays with complete pose tracking"
};
