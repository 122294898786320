import React from "react";
import { inject, observer } from "mobx-react";
import MLBTrackingAccuracyReportTable from "../components/accuracyReports/MLBTrackingAccuracyReportTable";
import IncidentTypes from "../constants/IncidentTypes";
import BaseMLBTrackingAccuracyReportContainer from "./BaseMLBTrackingAccuracyReportContainer";

class MLBTrackingAccuracyReportContainer extends React.Component {
  render() {
    const rootStore = this.props.rootStore;
    const reportSource = rootStore.mlbTrackingAccuracyReportSource;
    const description =
      "A real-time pitch-by-pitch data report that monitors the overall quality of Major League Baseball's data and video systems.<br /> <br /> <b>Note: Orange cells denote games with Club system tracking integrated</b>";

    const reportTable = (
      <MLBTrackingAccuracyReportTable
        data={rootStore.MLBGameActionStore.trackingAccuracyReportSource.data}
        gameStatuses={rootStore.veracityStore.gameStatuses}
        transitionToCreateIncident={
          rootStore.MLBGameActionStore.trackingAccuracyReportSource
            .transitionToCreateIncident
        }
        canCreateIncident={rootStore.authStore.hasIncidentEdit}
        groupsToShow={rootStore.tableControlsStore.state}
      />
    );
    return (
      <BaseMLBTrackingAccuracyReportContainer
        header="MLB Statcast Data & Coaching Video Report"
        description={description}
        reportSource={rootStore.MLBGameActionStore.trackingAccuracyReportSource}
        reportTable={reportTable}
        incidentTypesToFetch={[IncidentTypes.MLB, IncidentTypes.SYSTEM]}
        showSystemIncidentButton={rootStore.authStore.hasIncidentEdit}
      />
    );
  }
}

export default inject("rootStore")(
  observer(MLBTrackingAccuracyReportContainer),
);
