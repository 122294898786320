import React from "react";
import { inject, observer } from "mobx-react";
import gameActionOptions from "../constants/gameActionOptions";
import GameActionsDropDown from "../components/GameActionsDropDown";

class AmateurGameActionDropDownContainer extends React.Component {
  render() {
    const { AmateurGameActionStore } = this.props.rootStore;

    return (
      <GameActionsDropDown
        game={this.props.game}
        gameActionOptions={gameActionOptions}
        transitionToCreateIncident={
          AmateurGameActionStore.trackingAccuracyReportSource
            .transitionToCreateIncident
        }
        clearClippyCache={AmateurGameActionStore.clearClippyCache}
        clearStatsApiCache={AmateurGameActionStore.clearStatsApiCache}
        resendGame={AmateurGameActionStore.resendGame}
      />
    );
  }
}

export default inject("rootStore")(
  observer(AmateurGameActionDropDownContainer),
);
