import styled from "styled-components";

const Text = styled.div`
  width: inherit;
  position: relative;
  padding: 5px 0;

  &:hover {
    border: 1px solid #e9e9e9;
  }
`;

export default Text;
