export const floatToPercentFloor = (num) => {
  const numAsPercent = num * 100;
  return `${Math.floor(numAsPercent)}`;
};

export const floatToPercentCeil = (num) => {
  const numAsPercent = num * 100;
  return `${Math.ceil(numAsPercent)}`;
};

export const DateFormat = {
  PITCH_CRITERIA_DATE: "YYYY-MM-DD",
};
