import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import styled from "styled-components";
import PropTypes from "prop-types";
import rem from "../utils/rem";

const MetadataList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-size: ${rem(18)};

  & li {
    padding: 3px 0;
  }
`;

const MetadataLabel = styled.span`
  font-weight: bold;
`;

class IncidentMetadata extends React.PureComponent {
  render() {
    const { createdAt, incidentUpdates, homePlateUmpire } = this.props.incident;
    const latestUpdate = _.first(incidentUpdates);
    const { status, severity, eta } = latestUpdate;

    const estCreatedAtMoment = moment(createdAt).tz("America/New_York");
    const estEtaMoment = moment(eta).tz("America/New_York");

    return (
      <MetadataList>
        <li>
          <MetadataLabel>Opened: </MetadataLabel>
          {estCreatedAtMoment.format("LL")} at{" "}
          {estCreatedAtMoment.format("h:mm A")} EST
        </li>
        <li>
          <MetadataLabel>Status: </MetadataLabel>
          {status}
        </li>
        <li>
          <MetadataLabel>Severity: </MetadataLabel>
          {severity}
        </li>
        {eta ? (
          <li>
            <MetadataLabel>ETA: </MetadataLabel>
            {estEtaMoment.format("LLL")} EST
          </li>
        ) : null}
        {homePlateUmpire ? (
          <li>
            <MetadataLabel>Home Plate Umpire: </MetadataLabel>
            {homePlateUmpire}
          </li>
        ) : null}
      </MetadataList>
    );
  }
}

IncidentMetadata.propTypes = {
  incident: PropTypes.object.isRequired
};

export default IncidentMetadata;
