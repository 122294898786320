import React from "react";
import { inject, observer } from "mobx-react";
import BaseMLBTrackingAccuracyReportContainer from "./BaseMLBTrackingAccuracyReportContainer";
import NonMLBTrackingAccuracyReportTable from "../components/accuracyReports/NonMLBTrackingAccuracyReportTable";
import IncidentTypes from "../constants/IncidentTypes";
import HighAGameActionDropDownContainer from "./HighAGameActionDropDownContainer";

class HighATrackingAccuracyReportContainer extends React.Component {
  render() {
    const rootStore = this.props.rootStore;
    const description =
      "A real-time pitch-by-pitch data report that monitors the overall quality of data and video systems used for High A games.<br /> <br /> <b>Note: Orange cells denote games with Club system tracking integrated</b>";

    const reportTable = (
      <NonMLBTrackingAccuracyReportTable
        data={rootStore.HighAGameActionStore.trackingAccuracyReportSource.data}
        gameStatuses={rootStore.veracityStore.gameStatuses}
        canCreateIncident={rootStore.authStore.hasIncidentEdit}
        groupsToShow={rootStore.tableControlsStore.state}
        dropDownContainer={HighAGameActionDropDownContainer}
      />
    );
    return (
      <BaseMLBTrackingAccuracyReportContainer
        header="High A Statcast Data & Coaching Video Report"
        description={description}
        reportSource={
          rootStore.HighAGameActionStore.trackingAccuracyReportSource
        }
        reportTable={reportTable}
        incidentTypesToFetch={[IncidentTypes.HighA, IncidentTypes.SYSTEM]}
        showSystemIncidentButton={rootStore.authStore.hasIncidentEdit}
      />
    );
  }
}

export default inject("rootStore")(
  observer(HighATrackingAccuracyReportContainer),
);
