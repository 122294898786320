import React from "react";
import { inject, observer } from "mobx-react";
import moment from "moment-timezone";
import Modal from "react-modal";
import styled from "styled-components";

import { Table, Th, Tr, Td } from "./elements/Table";
import Text from "./elements/Text";

import Editable from "./Editable";
import VerifyDeleteModal from "./VerifyDeleteModal";

import Check from "./icons/Check";
import Exclamation from "./icons/Exclamation";
import Trash from "./icons/Trash";

const StatusIconWrapper = styled.span`
  padding-right: 5px;
`;

const DeleteIcon = styled.div`
  visibility: hidden;
  position: relative;
  float: right;
  left: 30px;
  top: -30px;
  height: 100%;
  background-color: white;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  color: red;

  ${Text}:hover + & {
    visibility: visible;
  }

  :hover {
    visibility: visible;
  }
`;

class GameIncidentUpdatesTable extends React.Component {
  constructor() {
    super();
    this.state = {
      modalIsOpen: false,
      updateToDelete: {}
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.setUpdateForDeletion = this.setUpdateForDeletion.bind(this);
  }

  toggleModal() {
    const { modalIsOpen } = this.state;
    this.setState({
      modalIsOpen: !modalIsOpen
    });
  }

  setUpdateForDeletion(incidentUpdate) {
    this.setState({ updateToDelete: incidentUpdate });
  }

  handleDelete() {
    const { veracityStore: store } = this.props.rootStore;
    const { deleteIncidentUpdate } = store;
    const { updateToDelete } = this.state;
    deleteIncidentUpdate(updateToDelete);
    this.toggleModal();
  }

  render() {
    const { veracityStore: store, authStore } = this.props.rootStore;
    const incident = store.singleIncident;
    const canEdit = authStore.hasIncidentEdit;
    const canDelete = canEdit && incident.incidentUpdates.length > 1;
    const update = store.updateIncidentUpdateName;
    const rows = incident.incidentUpdates.map(incidentUpdate => {
      const { id, createdAt, status, description } = incidentUpdate;
      const estCreatedAtMoment = moment(createdAt).tz("America/New_York");
      return (
        <Tr key={id}>
          <Td>
            <StatusIconWrapper>
              {status === "Open" ? (
                <Exclamation shape="circle" />
              ) : (
                <Check shape="circle" />
              )}
            </StatusIconWrapper>
          </Td>
          <Td>{estCreatedAtMoment.format("LL")}</Td>
          <Td>{estCreatedAtMoment.format("LT")}</Td>
          <Td>
            <div style={{ whiteSpace: "pre-wrap" }}>
              <Editable
                disabled={!canEdit}
                inputType="textarea"
                handleSave={update.bind(null, incidentUpdate)}
              >
                {description}
              </Editable>
              {canDelete && (
                <DeleteIcon
                  onClick={() => {
                    this.toggleModal();
                    this.setUpdateForDeletion(incidentUpdate);
                  }}
                >
                  <Trash />
                </DeleteIcon>
              )}
            </div>
          </Td>
        </Tr>
      );
    });

    return (
      <React.Fragment>
        <Table width="100%">
          <colgroup>
            <col width="2%" />
            <col width="10%" />
            <col width="10%" />
          </colgroup>
          <thead>
            <Tr>
              <Th />
              <Th>Date</Th>
              <Th>Updated At</Th>
              <Th textAlignment="left">Latest Update</Th>
            </Tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.toggleModal}
          ariaHideApp={false}
          className="deleteModal"
        >
          <VerifyDeleteModal
            message="Are you sure you want to remove this incident update?"
            toggleModal={this.toggleModal}
            handleDelete={() => this.handleDelete()}
          />
        </Modal>
      </React.Fragment>
    );
  }
}

export default inject("rootStore")(observer(GameIncidentUpdatesTable));
